import { getWalletClient } from '../wallet';

import { TotalProfitQuery } from './__generated__/sdk';
import { getCoreClient } from './client';

export async function getTotalProfit(): Promise<TotalProfitQuery> {
  const walletClient = await getWalletClient();
  const coreClient = await getCoreClient();
  if (!coreClient.enabled) {
    return walletClient.TotalProfit();
  }
  return coreClient.TotalProfit();
}
