import React, { FC, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useIntl } from 'react-intl';
import { useFeatureFlag } from 'configcat-react';

import { getWallet, QueryKeys, refetchIntervals } from '@investown/fe/api-sdk';
import { currencyFormat, defaultWalletCurrency } from '@investown/fe/common-utils';
import { TestIds } from '@investown/fe/test-utils/testIds';

import MenuItem from '../MenuItem/MenuItem';
import { fillColor, textColor } from '../utils';
import { PremiumPillWrapper } from '../PremiumPillWrapper';

import Typography from 'components/V2/Typography/Typography';
import Spacer from 'components/Spacer';
import SkeletonRow from 'components/SkeletonRow';
import ProfileIcon from 'components/V2/Icons/Profile';
import WalletIcon from 'components/V2/Icons/Wallet';
import PlusIcon from 'components/V2/Icons/Plus';
import { PATH_USER, PATH_WALLET } from 'routes/routesPaths';
import { FallbackFeatureFlagValues, FeatureFlags } from 'constants/FeatureFlags';

const WalletWithProfile: FC = () => {
  const theme = useContext(ThemeContext);
  const { pathname } = useLocation();
  const isWalletActive = PATH_WALLET === pathname;
  const wallet = useQuery(QueryKeys.Wallet, getWallet, {
    refetchInterval: refetchIntervals.thirtySeconds,
  });
  const intl = useIntl();

  const showPremium20Web = useFeatureFlag<boolean>(
    FeatureFlags.ShowPremium20Web,
    FallbackFeatureFlagValues[FeatureFlags.ShowPremium20Web]
  );
  const premium20FFisLoadedAndEnabled = showPremium20Web.value && !showPremium20Web.loading;

  return (
    <MenuContainer>
      <WalletButton to={PATH_WALLET} $active={isWalletActive} data-testid={TestIds.Wallet}>
        <WalletIcon fillColor={fillColor(isWalletActive, theme)} color={textColor(isWalletActive, theme)} />
        <Spacer width="medium" />
        {wallet.isLoading && <SkeletonRow width={100} />}

        {wallet.isSuccess && (
          <Typography variant="buttonBASESemiBold" color={isWalletActive ? 'brand' : 'strong'}>
            {currencyFormat({
              input: wallet.data.Wallet.availableBalance,
              currency: defaultWalletCurrency,
              locale: intl.locale,
            })}
          </Typography>
        )}
        <Spacer width="medium" />
        <PlusButton>
          <PlusIcon fillColor={fillColor(true, theme)} color={textColor(true, theme)} width="45px" />
        </PlusButton>
      </WalletButton>
      <Spacer width="regular" />
      {premium20FFisLoadedAndEnabled && (
        <>
          <PremiumPillWrapper />
          <Spacer width="regular" />
        </>
      )}
      <MenuItem menuItemLink={PATH_USER} icon={ProfileIcon} testId={TestIds.Profile} />
    </MenuContainer>
  );
};

const MenuContainer = styled.div`
  display: flex;
  z-index: 2;
`;

const WalletButton = styled(Link)<{ $active: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border: 1px solid;
  border-radius: ${({ theme }) => theme.borderRadius.extraLarge};
  padding: ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.small}
    ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.large};
  background: ${({ theme, $active }) =>
    $active ? theme.colorTokens.button.secondary.default : theme.colorTokens.surface.background};
  border-color: ${({ theme, $active }) =>
    $active ? theme.colorTokens.button.secondary.default : theme.colorTokens.stroke.medium};

  &:hover * {
    color: ${({ theme }) => theme.colorTokens.text.brand};

    path {
      stroke: ${({ theme }) => theme.colorTokens.text.brand};
    }
  }
`;

const PlusButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${({ theme }) => theme.colorTokens.button.secondary.default};
  width: 32px;
  height: 32px;
`;

export default WalletWithProfile;
