import { getPropertiesClient } from '../properties';

import { CountInvestedPropertiesQuery } from './__generated__/sdk';
import { getCoreClient } from './client';

export async function getCountPortfolioItems(): Promise<CountInvestedPropertiesQuery> {
  const propertiesClient = await getPropertiesClient();
  const coreClient = await getCoreClient();

  if (!coreClient.enabled) {
    return propertiesClient.CountInvestedProperties();
  }
  return coreClient.CountInvestedProperties();
}
