import { getWalletClient } from '../wallet';

import { WalletHistoryQuery } from './__generated__/sdk';
import { WalletTransactionFilterType } from './__generated__/types';
import { getCoreClient } from './client';

export interface GetWalletHistoryProps {
  page: number;
  perPage: number;
  sortField: string;
  sortOrder: string;
  filter: WalletTransactionFilterType;
}

export async function getWalletHistory({
  page,
  perPage,
  sortField,
  sortOrder,
  filter,
}: GetWalletHistoryProps): Promise<WalletHistoryQuery> {
  const walletClient = await getWalletClient();
  const coreClient = await getCoreClient();
  if (!coreClient.enabled) {
    return walletClient.WalletHistory({ page, perPage, sortField, sortOrder, filter });
  }
  return coreClient.WalletHistory({ page, perPage, sortField, sortOrder, filter });
}
