import { getWalletClient } from '../wallet';

import { UserInvestmentStatsQuery } from './__generated__/sdk';
import { getCoreClient } from './client';

export async function getUserInvestmentStats({
  currency = 'CZK',
}: {
  currency: string;
}): Promise<UserInvestmentStatsQuery> {
  const walletClient = await getWalletClient();
  const coreClient = await getCoreClient();
  if (!coreClient.enabled) {
    return walletClient.UserInvestmentStats({
      input: {
        currency,
      },
    });
  }
  return coreClient.UserInvestmentStats({
    input: {
      currency,
    },
  });
}
