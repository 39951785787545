import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { getPortfolioStatistics, PropertiesSDK, QueryKeys } from '@investown/fe/api-sdk';

export function usePortfolioStatistics(
  queryOptions?: UseQueryOptions<PropertiesSDK.PortfolioStatisticsQuery, Error>
): UseQueryResult<PropertiesSDK.PortfolioStatisticsQuery, Error> {
  return useQuery<PropertiesSDK.PortfolioStatisticsQuery, Error>(
    [QueryKeys.PortfolioStatistics],
    getPortfolioStatistics,
    {
      ...queryOptions,
    }
  );
}
