import { getPropertiesClient } from '../properties';

import { AverageYieldMonthlyHistoryQuery } from './__generated__/sdk';
import { getCoreClient } from './client';
import { PageRequest } from './model';

export async function getPageOfAverageYieldMonthlyHistory({
  page,
  perPage,
}: PageRequest): Promise<AverageYieldMonthlyHistoryQuery> {
  const propertiesClient = await getPropertiesClient();
  const coreClient = await getCoreClient();
  if (!coreClient.enabled) {
    return propertiesClient.AverageYieldMonthlyHistory({ page, perPage });
  }
  return coreClient.AverageYieldMonthlyHistory({ page, perPage });
}
