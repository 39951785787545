export enum LegalDocuments {
  FrameworkAgreementTermsAndConditions = 'https://www.investown.cz/vseobecne-podminky-k-ramcove-smlouve/',
  PrivacyPolicy = 'https://www.investown.cz/zasady-ochrany-osobnich-udaju/',
  PrivacyPolicy2022 = 'https://www.investown.cz/zasady-ochrany-osobnich-udaju-2021/',
  GeneralConditionsForParticiaptionInLoans = 'https://www.investown.cz/obecne-podminky-participaci-na-uverech/',
  PlatformRules = 'https://www.investown.cz/pravidla-platformy/',
  TermsAndConditions = 'https://www.investown.cz/vseobecne-podminky/',
  TermsAndConditionsLegalEntities = 'https://www.investown.cz/vseobecne-podminky-po/',
  InformationSheet = 'https://www.investown.cz/informacni-list/',
  ComplaintsCode = 'https://www.investown.cz/reklamacni-rad/',
  GeneralLoanConditions = 'https://www.investown.cz/obecne-uverove-podminky',
  GeneralLoanConditionsLegalEntities = 'https://www.investown.cz/obecne-uverove-podminky-po',
  InvestmentContract = 'https://www.investown.cz/investicni-smlouva',
}

export const participationInvestmentsNotAvailableFrom = new Date('2022-07-20T09:28Z');
