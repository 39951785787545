import { getPropertiesClient } from '../properties';

import {
  CountPageablePropertiesQuery,
  PropertyInvestmentRoundLevel,
  PropertyInvestmentRoundStatus,
  PropertyInvestmentRoundType,
} from './__generated__/sdk';
import { getCoreClient } from './client';

interface Filter {
  currentUsersTotalInvestmentGreaterThan?: number;
  investmentAmountAvailableEqualTo?: number;
  investmentAmountAvailableGreaterThan?: number;
  levelIn?: PropertyInvestmentRoundLevel[];
  typeIn?: PropertyInvestmentRoundType[];
  statusIn?: PropertyInvestmentRoundStatus[];
}

export async function getCountProperties({ filter }: { filter: Filter }): Promise<CountPageablePropertiesQuery> {
  const propertiesClient = await getPropertiesClient();
  const coreClient = await getCoreClient();
  const {
    currentUsersTotalInvestmentGreaterThan,
    investmentAmountAvailableEqualTo,
    investmentAmountAvailableGreaterThan,
    levelIn,
    typeIn,
    statusIn,
  } = filter;

  if (!coreClient.enabled) {
    return propertiesClient.CountPageableProperties({
      currentUsersTotalInvestment_gt: currentUsersTotalInvestmentGreaterThan,
      investmentAmountAvailable_eq: investmentAmountAvailableEqualTo,
      investmentAmountAvailable_gt: investmentAmountAvailableGreaterThan,
      level_in: levelIn,
      type_in: typeIn,
      status_in: statusIn,
    });
  }

  return coreClient.CountPageableProperties({
    currentUsersTotalInvestment_gt: currentUsersTotalInvestmentGreaterThan,
    investmentAmountAvailable_eq: investmentAmountAvailableEqualTo,
    investmentAmountAvailable_gt: investmentAmountAvailableGreaterThan,
    level_in: levelIn,
    type_in: typeIn,
    status_in: statusIn,
  });
}
