import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useFeatureFlag } from 'configcat-react';

import { calculateUiLevelProgress } from '@investown/fe/common-utils';
import { refetchIntervals, useUserDetails, useUserLevels } from '@investown/fe/api-sdk';

import PremiumPill, { PremiumPillLoadingSkeleton } from '../PremiumPill/PremiumPill';

import { FallbackFeatureFlagValues, FeatureFlags } from 'constants/FeatureFlags';
import { usePortfolioStatistics } from 'hooks/usePortfolioStatistics';
import { PATH_USER_MEMBERSHIP_LEVELS } from 'routes/routesPaths';

export const PremiumPillWrapper: FC = () => {
  const {
    data: userDetailsData,
    isLoading: userDetailsIsLoading,
    isError: userDetailsIsError,
    isIdle: userDetailsIsIdle,
  } = useUserDetails({ refetchInterval: refetchIntervals.thirtySeconds });
  const showPremium20Web = useFeatureFlag<boolean>(
    FeatureFlags.ShowPremium20Web,
    FallbackFeatureFlagValues[FeatureFlags.ShowPremium20Web]
  );
  const premium20FFisLoadedAndEnabled = showPremium20Web.value && !showPremium20Web.loading;
  const {
    data: portfolioStatisticsData,
    isLoading: portfolioStatisticsIsLoading,
    isError: portfolioStatisticsIsError,
    isIdle: portfolioStatisticsIsIdle,
  } = usePortfolioStatistics({
    refetchInterval: refetchIntervals.thirtySeconds,
    refetchOnMount: 'always',
  });
  const {
    data: userLevelsData,
    isLoading: userLevelsIsLoading,
    isError: userLevelsIsError,
    isIdle: userLevelsIsIdle,
  } = useUserLevels({});

  if (!premium20FFisLoadedAndEnabled || userLevelsIsError || userDetailsIsError || portfolioStatisticsIsError) {
    return null;
  }

  if (
    portfolioStatisticsIsLoading ||
    userLevelsIsLoading ||
    userDetailsIsLoading ||
    portfolioStatisticsIsLoading ||
    userDetailsIsIdle ||
    userLevelsIsIdle ||
    portfolioStatisticsIsIdle
  ) {
    return <PremiumPillLoadingSkeleton />;
  }

  const levelProgress = calculateUiLevelProgress({
    currentLevel: userDetailsData.level.currentLevel,
    levels: userLevelsData.levels,
    portfolioSize: portfolioStatisticsData.portfolioStatistics.portfolioSize,
  });

  if (!levelProgress) {
    return null;
  }

  return (
    <Link to={PATH_USER_MEMBERSHIP_LEVELS}>
      <PremiumPill
        size="large"
        levels={userLevelsData.levels}
        userLevel={userDetailsData.level.currentLevel}
        userLevelProgress={levelProgress.isLastLevel ? undefined : levelProgress.percentDone}
      />
    </Link>
  );
};
