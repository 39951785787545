import { getPropertiesClient } from '../properties';

import { getCoreClient } from './client';
import { ActiveSecondaryMarketRemainingOffersSumsQuery } from './__generated__/sdk';

export async function getActiveSecondaryMarketRemainingOffersSums(
  investmentRoundId: string
): Promise<ActiveSecondaryMarketRemainingOffersSumsQuery> {
  const propertiesClient = await getPropertiesClient();
  const coreClient = await getCoreClient();
  if (!coreClient.enabled) {
    return propertiesClient.ActiveSecondaryMarketRemainingOffersSums({ investmentRoundId });
  }
  return coreClient.ActiveSecondaryMarketRemainingOffersSums({ investmentRoundId });
}
