import { getPropertiesClient } from '../properties';

import { RepaymentScheduleQuery } from './__generated__/sdk';
import { getCoreClient } from './client';

export async function getRepaymentSchedule(propertyInvestmentRoundId: string): Promise<RepaymentScheduleQuery> {
  const propertiesClient = await getPropertiesClient();
  const coreClient = await getCoreClient();
  if (!coreClient.enabled) {
    return propertiesClient.RepaymentSchedule({ propertyInvestmentRoundId });
  }
  return coreClient.RepaymentSchedule({ propertyInvestmentRoundId });
}
