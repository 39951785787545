import { getWalletClient } from '../wallet';

import { TotalUserLevelBonusYieldQuery } from './__generated__/sdk';
import { getCoreClient } from './client';

export async function getTotalUserLevelBonusYield({
  propertyInvestmentRoundId,
  onlyCurrentMonth = false,
}: {
  propertyInvestmentRoundId?: string;
  onlyCurrentMonth?: boolean;
}): Promise<TotalUserLevelBonusYieldQuery> {
  const walletClient = await getWalletClient();
  const coreClient = await getCoreClient();
  if (!coreClient.enabled) {
    return walletClient.TotalUserLevelBonusYield({
      input: {
        propertyInvestmentRoundId,
        onlyCurrentMonth,
      },
    });
  }
  return coreClient.TotalUserLevelBonusYield({
    input: {
      propertyInvestmentRoundId,
      onlyCurrentMonth,
    },
  });
}
