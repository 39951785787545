import { getPropertiesClient } from '../properties';

import { getCoreClient } from './client';
import { PortfolioStatisticsQuery } from './__generated__/sdk';

export async function getPortfolioStatistics(): Promise<PortfolioStatisticsQuery> {
  const propertiesClient = await getPropertiesClient();
  const coreClient = await getCoreClient();
  if (!coreClient.enabled) {
    return propertiesClient.PortfolioStatistics();
  }
  return coreClient.PortfolioStatistics();
}
