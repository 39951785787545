import { getPropertiesClient } from '../properties';

import { InvestmentsQuery } from './__generated__/sdk';
import { getCoreClient } from './client';

export async function getInvestments(slug: string): Promise<InvestmentsQuery> {
  const propertiesClient = await getPropertiesClient();
  const coreClient = await getCoreClient();
  if (!coreClient.enabled) {
    return propertiesClient.Investments({ slug });
  }
  return coreClient.Investments({ slug });
}
