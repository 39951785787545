export enum TestIds {
  SignInUsername = 'signInUsername',
  SignInPassword = 'signInPassword',
  SignInTitle = 'signInTitle',
  SignInForgottenPassword = 'signInForgottenPassword',
  SignInSignUpButton = 'signInSignUpButton',
  LegalEntitySignInSignUpButton = 'legalEntitySignInSignUpButton',
  LegalEntitySignUpIdentificationNumberConfirmButton = 'legalEntitySignUpIdentificationNumberConfirmButton',
  LegalEntitySignUpIdentificationNumberBackArrowButton = 'legalEntitySignUpIdentificationNumberBackArrowButton',
  LegalEntitySignUpIdentificationNumberInput = 'legalEntitySignUpIdentificationNumberInput',
  SignInConfirmButton = 'signInConfirmButton',
  SignOutSuccessfulLogoutAlert = 'signOutSuccessfulLogoutAlert',
  SignUpEmail = 'signUpEmail',
  SignUpEmailConfirmButton = 'signUpEmailConfirmButton',
  SignUpSignInButton = 'signUpSignInButton',
  SignUpCredentialsBackArrow = 'signUpCredentialsBackArrow',
  SignUpCredentialsTitle = 'signUpCredentialsTitle',
  SignUpCredentialsFirstName = 'signUpCredentialsFirstName',
  SignUpCredentialsLastName = 'signUpCredentialsLastname',
  SignUpPasswordInput = 'SignUpPasswordInput',
  SignUpPasswordNewsletter = 'signUpCredentialsNewsletter',
  SignUpPasswordHasReferralCode = 'signUpCredentialsReferralCode',
  SignUpPasswordReferralCode = 'signUpCredentialsReferralCode',
  SignUpPasswordPersonalization = 'signUpPasswordPersonalization',
  SignUpPasswordNewProjects = 'signUpPasswordNewProjects',
  SignUpCredentialsConfirmButton = 'signUpCredentialsConfirmButton',
  SignUpPasswordConfirmButton = 'signUpPasswordConfirmButton',
  UserMenuLogoutButton = 'userMenuLogoutButton',
  UserMenuUserProfile = 'userMenuUserProfile',
  ForgottenPasswordInput = 'forgottenPasswordInput',
  ForgottenPasswordConfirmButton = 'forgottenPasswordConfirmButton',
  ForgottenPasswordSuccess = 'forgottenPasswordSuccess',
  SecondaryMarket = 'secondaryMarket',
  OpenProperties = 'openProperties',
  InvestedProperties = 'investedProperties',
  FullProperties = 'fullProperties',
  InviteFriends = 'inviteFriends',
  Wallet = 'wallet',
  App = 'app',
  Properties = 'properties',
  Dashboard = 'dashboard',
  Portfolio = 'portfolio',
  Transactions = 'transactions',
  Profile = 'profile',
  VariableSymbol = 'variableSymbol',
  SubmitButton = 'submitButton',
  SelectAccount = 'selectAccount',
  UserAccount = 'userAccount',
  Amount = 'amount',
  HistoryTable = 'historyTable',
  Logout = 'logout',
  NeedHelp = 'needHelp',
  NavbarLogo = 'navbarLogo',
  TopStar = 'topStar',
  TopOpportunities = 'topOpportunities',
  ShowAll = 'showAll',
  AvailableMoney = 'availableMoney',
  Tooltip = 'tooltip',
  TopOpportunityItem = 'topOpportunityItem',
  PropertyImage = 'propertyImage',
  InvestmentTime = 'investmentTime',
  PropertyName = 'propertyName',
  InvestmentOpportunitiesTitle = 'investmentOpportunitiesTitle',
  InvestorData = 'investorData',
  CompanyInfo = 'CompanyInfo',
  Stepper = 'stepper',
  Invest = 'invest',
  AmountToInvest = 'amountToInvest',
  SubmitInvestment = 'submitInvestment',
  InvestedAmount = 'investedAmount',
  CloseSuccesfullInvestment = 'closeSuccesfullInvestment',
  WithdrawFromInvestmentDropdown = 'withdrawFromInvestmentDropdown',
  UnderstandSuccessfulWithdrawalFromInvestmentButton = 'understandSuccessfulWithdrawalFromInvestmentButton',
  WithdrawFromInvestment = 'withdrawFromInvestment',
  ConfirmWithdrawFromInvestment = 'confirmWithdrawFromInvestment',
  KeepInvestment = 'keepInvestment',
  SuccessfulWithdrawalFromInvestmentModal = 'successfulWithdrawalFromInvestmentModal',
  OfferInvestment = 'offerInvestment',
  FinishVerificationButton = 'finishVerificationButton',
  MoneyOriginContinueButton = 'moneyOriginContinueButton',
  PepContinueButton = 'pepContinueButton',
  OpenVeriffButton = 'openVeriffButton',
  InvestorQuestionnaireContinueButton = 'investorQuestionnaireContinueButton',
  FirstInvestorQuestionnairePartSkipButton = 'firstInvestorQuestionnairePartSkipButton',
  MultipleChoiceInvestorQuestionnaireContinueButton = 'multipleChoiceInvestorQuestionnaireContinueButton',
  AbilityToBearLossContinueButton = 'abilityToBearLossContinueButton',
  AbilityToBearLossNumberInput = 'abilityToBearLossNumberInput',
  ContinueButtonOnInitialDisclaimerScreen = 'continueButtonOnInitialDisclaimerScreen',
  ContinueButtonAfterFirstPartQuestionnaireSkip = 'continueButtonAfterFirstPartQuestionnaireSkip',
  AbilityToBearLossFinalContinueButton = 'abilityToBearLossFinalContinueButton',
  AbilityToBearLossShareCheckbox = 'abilityToBearLossShareCheckbox',
  InvestmentQuestionnairePartTwoContinueButton = 'investmentQuestionnairePartTwoContinueButton',
  MarketplaceBids = 'marketplaceBids',
  MarketplaceBidsTitle = 'marketplaceBidsTitle',
  CancelAllSecondaryMarketOffers = 'cancelAllSecondaryMarketOffers',
  VipGateButton = 'vipGateButton',
  CloseModal = 'closeModal',
  ReferralCopyButton = 'referralCopyButton',
  SubmitSecondaryMarketBid = 'submitSecondaryMarketBid',
  CloseSecondaryMarketBidSuccess = 'closeSecondaryMarketBidSuccess',
  CloseSecondaryMarketBidDetail = 'closeSecondaryMarketBidDetail',
  OpenSecondaryMarketBidDetail = 'openSecondaryMarketBidDetail',
  SignInLinkToLandingPage = 'signInLinkToLandingPage',
  InvestownReviews = 'investownReviews',
  AgreeWithNewInvestmentContract = 'agreeWithNewInvestmentContract',
  CloseNewInvestmentContractModal = 'closeNewInvestmentContractModal',
}
