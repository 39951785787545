import { getWalletClient } from './client';
import {
  CreateUsersFinancialPlanInputType,
  CreateUsersFinancialPlanMutation,
  FinishUsersFinancialPlanMutation,
  MyFinancialPlansQuery,
  UpdateUsersFinancialPlanInputType,
  UpdateUsersFinancialPlanMutation,
} from './__generated__/sdk';

export async function getMyFinancialPlans(): Promise<MyFinancialPlansQuery> {
  const client = await getWalletClient();
  return client.MyFinancialPlans();
}

export async function createUsersFinancialPlan(
  input: CreateUsersFinancialPlanInputType
): Promise<CreateUsersFinancialPlanMutation> {
  const client = await getWalletClient();
  return client.CreateUsersFinancialPlan({ input });
}

export async function updateUsersFinancialPlan(params: {
  financialPlanId: string;
  input: UpdateUsersFinancialPlanInputType;
}): Promise<UpdateUsersFinancialPlanMutation> {
  const client = await getWalletClient();
  return client.UpdateUsersFinancialPlan({ id: params.financialPlanId, input: params.input });
}

export async function finishUsersFinancialPlan(params: {
  financialPlanId: string;
}): Promise<FinishUsersFinancialPlanMutation> {
  const client = await getWalletClient();
  return client.FinishUsersFinancialPlan({ id: params.financialPlanId });
}
