import { getWalletClient } from '../wallet';

import { MonthlyProfitQuery } from './__generated__/sdk';
import { getCoreClient } from './client';

export interface GetMonthlyProfitProps {
  page?: number;
  perPage: number;
}

export async function getMonthlyProfit({ page = 0, perPage }: GetMonthlyProfitProps): Promise<MonthlyProfitQuery> {
  const walletClient = await getWalletClient();
  const coreClient = await getCoreClient();
  if (!coreClient.enabled) {
    return walletClient.MonthlyProfit({ page, perPage });
  }
  return coreClient.MonthlyProfit({ page, perPage });
}
