export const deprecatedSpacing = {
  small: '5px',
  medium: '10px',
  large: '15px',
  extraLarge: '20px',
  huge: '30px',
  massive: '60px',
};

export const spacing = {
  small: '4px',
  medium: '8px',
  regular: '12px',
  large: '16px',
  larger: '20px',
  extraLarge: '24px',
  huge: '32px',
  massive: '40px',
  extensive: '62px',
};
