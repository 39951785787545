import { getPropertiesClient } from '../properties';

import { RelatedPropertiesQuery, RelatedPropertiesQueryVariables } from './__generated__/sdk';
import { getCoreClient } from './client';

export async function getPartnerRelatedProjects({
  slug,
  page,
  perPage,
}: RelatedPropertiesQueryVariables): Promise<RelatedPropertiesQuery> {
  const propertiesClient = await getPropertiesClient();
  const coreClient = await getCoreClient();
  if (!coreClient.enabled) {
    return propertiesClient.RelatedProperties({
      slug,
      page,
      perPage,
    });
  }
  return coreClient.RelatedProperties({
    slug,
    page,
    perPage,
  });
}
