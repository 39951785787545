import { colors } from '@investown/fe/ui-utils/colors';
import { deprecatedSpacing, spacing } from '@investown/fe/ui-utils/spacing';
import { fontLineHeight, fontWeight, fontSize, deprecatedFontSize } from '@investown/fe/ui-utils/fontVariables';
import { colorTokens } from '@investown/fe/common-utils';

const globals = {
  formElementsHeight: '46px',
  radiusZero: '0',
  radiusTiny: '4px',
  radiusMedium: '6px',
  radiusSmall: '8px',
  radiusLarge: '16px',
  radiusExtraLarge: '20px',
  radiusCircle: '50%',
  maxInputWidth: '350px',
};

export const theme = {
  colorTokens,
  /** @deprecated use colorTokes instead */
  color: {
    primary: colors.primary.base,
    primaryDisabled: colors.primary.disabledFloating,
    primaryHover: colors.primary.active,
    primaryFaded: colors.primary.faded,
    success: colors.success.base,
    successFaded: colors.success.faded,
    positive: colors.success.active,
    successBackground: colors.success.faded,
    warning: colors.warning.base,
    warningFaded: colors.warning.faded,
    alert: colors.alert.heavy,
    alertBase: colors.alert.base,
    textColor: colors.text.base,
    textOne: colors.text.one,
    textTwo: colors.text.two,
    titleColor: colors.text.base,
    textFaded: colors.text.faded,
    moreFaded: colors.text.disabled,
    contentBackground: colors.primitive.white,
    bodyBackground: colors.primitive.grey,
    white: colors.primitive.white,
    darkGrey: colors.primitive.darkGrey,
    black: colors.primitive.black,
    transparent: colors.transparent,
    premium: colors.premium,
  },
  progressBar: {
    success: colors.success.base,
    primaryOne: colors.primary.base,
    primaryTwo: colors.primary.active,
    background: colors.primary.disabled,
    backgroundPrimary: colors.primary.faded,
  },
  borderRadius: {
    zero: globals.radiusZero,
    tiny: globals.radiusTiny,
    medium: globals.radiusMedium,
    small: globals.radiusSmall,
    large: globals.radiusLarge,
    extraLarge: globals.radiusExtraLarge,
    circle: globals.radiusCircle,
  },
  font: {
    // remove these one after web 2.0
    size: deprecatedFontSize,
    // remove these one after web 2.0
    weight: {
      thin: fontWeight.regular,
      light: fontWeight.regular,
      regular: fontWeight.regular,
      medium: fontWeight.medium,
      semiBold: fontWeight.semiBold,
      bold: fontWeight.bold,
    },
  },
  typography: {
    // Display fonts

    display4XLRegular: {
      size: fontSize.display.XXXXL,
      weight: fontWeight.regular,
      lineHeight: fontLineHeight.display.XXXXL,
    },
    display4XLMedium: {
      size: fontSize.display.XXXXL,
      weight: fontWeight.medium,
      lineHeight: fontLineHeight.display.XXXXL,
    },
    display4XLSemiBold: {
      size: fontSize.display.XXXXL,
      weight: fontWeight.semiBold,
      lineHeight: fontLineHeight.display.XXXXL,
    },
    display3XLRegular: {
      size: fontSize.display.XXXL,
      weight: fontWeight.regular,
      lineHeight: fontLineHeight.display.XXXL,
    },
    display3XLMedium: {
      size: fontSize.display.XXXL,
      weight: fontWeight.medium,
      lineHeight: fontLineHeight.display.XXXL,
    },
    display3XLSemiBold: {
      size: fontSize.display.XXXL,
      weight: fontWeight.semiBold,
      lineHeight: fontLineHeight.display.XXXL,
    },
    display2XLRegular: {
      size: fontSize.display.XXL,
      weight: fontWeight.regular,
      lineHeight: fontLineHeight.display.XXL,
    },
    display2XLMedium: {
      size: fontSize.display.XXL,
      weight: fontWeight.medium,
      lineHeight: fontLineHeight.display.XXL,
    },
    display2XLSemiBold: {
      size: fontSize.display.XXL,
      weight: fontWeight.semiBold,
      lineHeight: fontLineHeight.display.XXL,
    },
    displayXLRegular: {
      size: fontSize.display.XL,
      weight: fontWeight.regular,
      lineHeight: fontLineHeight.display.XL,
    },
    displayXLMedium: {
      size: fontSize.display.XL,
      weight: fontWeight.medium,
      lineHeight: fontLineHeight.display.XL,
    },
    displayXLSemiBold: {
      size: fontSize.display.XL,
      weight: fontWeight.semiBold,
      lineHeight: fontLineHeight.display.XL,
    },
    displayLGRegular: {
      size: fontSize.display.LG,
      weight: fontWeight.regular,
      lineHeight: fontLineHeight.display.LG,
    },
    displayLGMedium: {
      size: fontSize.display.LG,
      weight: fontWeight.medium,
      lineHeight: fontLineHeight.display.LG,
    },
    displayLGSemiBold: {
      size: fontSize.display.LG,
      weight: fontWeight.semiBold,
      lineHeight: fontLineHeight.display.LG,
    },
    displayMDRegular: {
      size: fontSize.display.MD,
      weight: fontWeight.regular,
      lineHeight: fontLineHeight.display.MD,
    },
    displayMDMedium: {
      size: fontSize.display.MD,
      weight: fontWeight.medium,
      lineHeight: fontLineHeight.display.MD,
    },
    displayMDSemiBold: {
      size: fontSize.display.MD,
      weight: fontWeight.semiBold,
      lineHeight: fontLineHeight.display.MD,
    },
    displaySMRegular: {
      size: fontSize.display.SM,
      weight: fontWeight.regular,
      lineHeight: fontLineHeight.display.SM,
    },
    displaySMMedium: {
      size: fontSize.display.SM,
      weight: fontWeight.medium,
      lineHeight: fontLineHeight.display.SM,
    },
    displaySMSemiBold: {
      size: fontSize.display.SM,
      weight: fontWeight.semiBold,
      lineHeight: fontLineHeight.display.SM,
    },
    displayXSRegular: {
      size: fontSize.display.XS,
      weight: fontWeight.regular,
      lineHeight: fontLineHeight.display.XS,
    },
    displayXSMedium: {
      size: fontSize.display.XS,
      weight: fontWeight.medium,
      lineHeight: fontLineHeight.display.XS,
    },
    displayXSSemiBold: {
      size: fontSize.display.XS,
      weight: fontWeight.semiBold,
      lineHeight: fontLineHeight.display.XS,
    },

    // Body fonts

    body2XLRegular: {
      size: fontSize.body.XXL,
      weight: fontWeight.regular,
      lineHeight: fontLineHeight.body.XXL,
    },
    body2XLMedium: {
      size: fontSize.body.XXL,
      weight: fontWeight.medium,
      lineHeight: fontLineHeight.body.XXL,
    },
    body2XLSemiBold: {
      size: fontSize.body.XXL,
      weight: fontWeight.semiBold,
      lineHeight: fontLineHeight.body.XXL,
    },
    bodyXLRegular: {
      size: fontSize.body.XL,
      weight: fontWeight.regular,
      lineHeight: fontLineHeight.body.XL,
    },
    bodyXLMedium: {
      size: fontSize.body.XL,
      weight: fontWeight.medium,
      lineHeight: fontLineHeight.body.XL,
    },
    bodyXLSemiBold: {
      size: fontSize.body.XL,
      weight: fontWeight.semiBold,
      lineHeight: fontLineHeight.body.XL,
    },
    bodyXLBold: {
      size: fontSize.body.XL,
      weight: fontWeight.bold,
      lineHeight: fontLineHeight.body.XL,
    },
    bodyLGRegular: {
      size: fontSize.body.LG,
      weight: fontWeight.regular,
      lineHeight: fontLineHeight.body.LG,
    },
    bodyLGMedium: {
      size: fontSize.body.LG,
      weight: fontWeight.medium,
      lineHeight: fontLineHeight.body.LG,
    },
    bodyLGSemiBold: {
      size: fontSize.body.LG,
      weight: fontWeight.semiBold,
      lineHeight: fontLineHeight.body.LG,
    },
    bodyBASERegular: {
      size: fontSize.body.BASE,
      weight: fontWeight.regular,
      lineHeight: fontLineHeight.body.BASE,
    },
    bodyBASEMedium: {
      size: fontSize.body.BASE,
      weight: fontWeight.medium,
      lineHeight: fontLineHeight.body.BASE,
    },
    bodyBASESemiBold: {
      size: fontSize.body.BASE,
      weight: fontWeight.semiBold,
      lineHeight: fontLineHeight.body.BASE,
    },
    bodySMRegular: {
      size: fontSize.body.SM,
      weight: fontWeight.regular,
      lineHeight: fontLineHeight.body.SM,
    },
    bodySMMedium: {
      size: fontSize.body.SM,
      weight: fontWeight.medium,
      lineHeight: fontLineHeight.body.SM,
    },
    bodySMSemiBold: {
      size: fontSize.body.SM,
      weight: fontWeight.semiBold,
      lineHeight: fontLineHeight.body.SM,
    },
    bodyXSRegular: {
      size: fontSize.body.XS,
      weight: fontWeight.regular,
      lineHeight: fontLineHeight.body.XS,
    },
    bodyXSMedium: {
      size: fontSize.body.XS,
      weight: fontWeight.medium,
      lineHeight: fontLineHeight.body.XS,
    },
    bodyXSSemiBold: {
      size: fontSize.body.XS,
      weight: fontWeight.semiBold,
      lineHeight: fontLineHeight.body.XS,
    },
    bodyXXSRegular: {
      size: fontSize.body.XXS,
      weight: fontWeight.regular,
      lineHeight: fontLineHeight.body.XXS,
    },
    bodyXXSMedium: {
      size: fontSize.body.XXS,
      weight: fontWeight.medium,
      lineHeight: fontLineHeight.body.XXS,
    },
    bodyXXSSemiBold: {
      size: fontSize.body.XXS,
      weight: fontWeight.semiBold,
      lineHeight: fontLineHeight.body.XXS,
    },

    // Button fonts

    buttonLGRegular: {
      size: fontSize.button.LG,
      weight: fontWeight.regular,
      lineHeight: fontLineHeight.button.XXL,
    },
    buttonLGMedium: {
      size: fontSize.button.LG,
      weight: fontWeight.medium,
      lineHeight: fontLineHeight.button.XXL,
    },
    buttonLGSemiBold: {
      size: fontSize.button.LG,
      weight: fontWeight.semiBold,
      lineHeight: fontLineHeight.button.XXL,
    },
    buttonBASERegular: {
      size: fontSize.button.BASE,
      weight: fontWeight.regular,
      lineHeight: fontLineHeight.button.BASE,
    },
    buttonBASEMedium: {
      size: fontSize.button.BASE,
      weight: fontWeight.medium,
      lineHeight: fontLineHeight.button.BASE,
    },
    buttonBASESemiBold: {
      size: fontSize.button.BASE,
      weight: fontWeight.semiBold,
      lineHeight: fontLineHeight.button.BASE,
    },
    buttonSMRegular: {
      size: fontSize.button.SM,
      weight: fontWeight.regular,
      lineHeight: fontLineHeight.button.SM,
    },
    buttonSMMedium: {
      size: fontSize.button.SM,
      weight: fontWeight.medium,
      lineHeight: fontLineHeight.button.SM,
    },
    buttonSMSemiBold: {
      size: fontSize.button.SM,
      weight: fontWeight.semiBold,
      lineHeight: fontLineHeight.button.SM,
    },
    buttonXSRegular: {
      size: fontSize.button.XS,
      weight: fontWeight.regular,
      lineHeight: fontLineHeight.button.XS,
    },
    buttonXSMedium: {
      size: fontSize.button.XS,
      weight: fontWeight.medium,
      lineHeight: fontLineHeight.button.XS,
    },
    buttonXSSemiBold: {
      size: fontSize.button.XS,
      weight: fontWeight.semiBold,
      lineHeight: fontLineHeight.button.XS,
    },

    // Label fonts

    labelLGRegular: {
      size: fontSize.label.LG,
      weight: fontWeight.regular,
      lineHeight: fontLineHeight.label.LG,
    },
    labelLGMedium: {
      size: fontSize.label.LG,
      weight: fontWeight.medium,
      lineHeight: fontLineHeight.label.LG,
    },
    labelLGSemiBold: {
      size: fontSize.label.LG,
      weight: fontWeight.semiBold,
      lineHeight: fontLineHeight.label.LG,
    },
    labelBASERegular: {
      size: fontSize.label.BASE,
      weight: fontWeight.regular,
      lineHeight: fontLineHeight.label.BASE,
    },
    labelBASEMedium: {
      size: fontSize.label.BASE,
      weight: fontWeight.medium,
      lineHeight: fontLineHeight.label.BASE,
    },
    labelBASESemiBold: {
      size: fontSize.label.BASE,
      weight: fontWeight.semiBold,
      lineHeight: fontLineHeight.label.BASE,
    },
    labelSSRegular: {
      size: fontSize.label.SS,
      weight: fontWeight.regular,
      lineHeight: fontLineHeight.label.SS,
    },
    labelSSMedium: {
      size: fontSize.label.SS,
      weight: fontWeight.medium,
      lineHeight: fontLineHeight.label.SS,
    },
    labelSSSemiBold: {
      size: fontSize.label.SS,
      weight: fontWeight.semiBold,
      lineHeight: fontLineHeight.label.SS,
    },
    labelSMRegular: {
      size: fontSize.label.SM,
      weight: fontWeight.regular,
      lineHeight: fontLineHeight.label.SM,
    },
    labelSMMedium: {
      size: fontSize.label.SM,
      weight: fontWeight.medium,
      lineHeight: fontLineHeight.label.SM,
    },
    labelSMSemiBold: {
      size: fontSize.label.SM,
      weight: fontWeight.semiBold,
      lineHeight: fontLineHeight.label.SM,
    },
    labelXSRegular: {
      size: fontSize.label.XS,
      weight: fontWeight.regular,
      lineHeight: fontLineHeight.label.XS,
    },
    labelXSMedium: {
      size: fontSize.label.XS,
      weight: fontWeight.medium,
      lineHeight: fontLineHeight.label.XS,
    },
    labelXSSemiBold: {
      size: fontSize.label.XS,
      weight: fontWeight.semiBold,
      lineHeight: fontLineHeight.label.XS,
    },
  },
  icons: {
    size: {
      nano: '12px',
      mini: '15px',
      small: '20px',
      medium: '25px',
    },
  },
  input: {
    height: globals.formElementsHeight,
    borderRadius: globals.radiusSmall,
    maxWidth: globals.maxInputWidth,
    borderColor: colors.primary.faded,
    focusBorderColor: colors.primary.base,
  },
  margins: {
    small: deprecatedSpacing.small,
    medium: deprecatedSpacing.medium,
    large: deprecatedSpacing.large,
    extraLarge: deprecatedSpacing.extraLarge,
    huge: deprecatedSpacing.huge,
    massive: deprecatedSpacing.massive,
  },
  paddings: {
    small: deprecatedSpacing.small,
    medium: deprecatedSpacing.medium,
    large: deprecatedSpacing.large,
    extraLarge: deprecatedSpacing.extraLarge,
    huge: deprecatedSpacing.huge,
    massive: deprecatedSpacing.massive,
  },
  spacing: {
    small: spacing.small,
    medium: spacing.medium,
    regular: spacing.regular,
    large: spacing.large,
    larger: spacing.larger,
    extraLarge: spacing.extraLarge,
    huge: spacing.huge,
    massive: spacing.massive,
    extensive: spacing.extensive,
  },
  breakpoints: {
    mini: '320px',
    small: '576px',
    medium: '768px',
    large: '992px',
    extraLarge: '1280px',
  },
  button: {
    color: {
      primary: colors.primary.base,
      primaryHover: colors.primary.active,
      highlight: colors.highlight.base,
      highlightHover: colors.highlight.active,
      alertHover: colors.alert.base,
      errorLinkHover: colors.alert.active,
    },
    compactHeight: '36px',
    maxWidth: '350px',
    lineHeight: '16px',
    fontSize: deprecatedFontSize.medium,
    fontWeight: '500',
    borderRadius: globals.radiusSmall,
  },
  promoBanner: {
    color: colors.primary.faded,
    width: '416px',
    height: '96px',
    textOffsetLeft: '99px',
    image: {
      width: '112px',
      height: '96px',
    },
    borderRadius: globals.radiusLarge,
  },
  onboardingBanner: {
    backgroundGradientOne: colors.primary.base,
    backgroundGradientTwo: colors.primary.active,
    textColor: colors.primitive.white,
  },
  widget: {
    borderRadius: globals.radiusLarge,
    background: {
      premium: colors.primitive.black,
      secondary: colors.secondary.base,
      success: colors.success.faded,
      warning: colorTokens.utility.warning.subtle,
      alert: colors.alert.faded,
    },
    size: {
      tile: '85px',
      medium: '190px',
    },
  },
  container: {
    maxWidth: '1280px',
  },
  form: {
    narrow: '350px',
    wide: '450px',
    ultraWide: '600px',
  },
  tag: {
    color: {
      primary: colors.primary.base,
      alert: colors.alert.base,
      success: colors.success.active,
      warning: colors.warning.base,
      default: colors.text.base,
      highlight: colors.highlight.base,
      secondary: colors.text.faded,
      error: colors.error.base,
    },
    background: {
      success: colors.success.faded,
      primary: colors.primary.faded,
      secondary: colors.secondary.background,
      alert: colors.alert.faded,
      warning: colors.warning.faded,
      default: colors.primitive.grey,
      highlight: colors.highlight.faded,
      error: colors.error.faded,
    },
    border: {
      default: colors.primitive.darkGrey,
      faded: colors.primitive.grey,
    },
    borderRadius: globals.radiusSmall,
  },
  border: {
    faded: colors.primitive.grey,
    lessFaded: colors.primitive.darkGrey,
  },
  alert: {
    background: {
      primary: colors.secondary.base,
    },
  },
  tab: {
    background: {
      base: colors.primitive.grey,
      hover: colors.primitive.darkGrey,
      active: colors.text.base,
    },
  },
  topbar: {
    height: '64px',
  },
  footer: {
    height: '66px',
  },
};

export type MarginSizeName = keyof typeof theme.margins;
export type SpacingSizeName = keyof typeof theme.spacing;
/** @deprecated use types in styles/colors instead */
export type ColorsName = keyof typeof theme.color;
export type FormSizeName = keyof typeof theme.form;
/** @deprecated use types in styles/colors instead */
export type BorderColorsName = keyof typeof theme.border;
export type BorderRadiusName = keyof typeof theme.borderRadius;
export type FontWeightName = keyof typeof theme.font.weight;
export type FontSizeName = keyof typeof theme.font.size;
export type TypographyName = keyof typeof theme.typography;
/** @deprecated use types in styles/colors instead */
export type WidgetBackgroundName = keyof typeof theme.widget.background;
/** @deprecated use types in styles/colors instead */
export type ProgressBarColorsName = keyof typeof theme.progressBar;
