import { createGraphQLClient } from '../api';
import { sdkFunctionWrapper } from '../utils';

import { getSdk } from './__generated__/sdk';

let coreGQLEndpoint: string;
let coreApiEnabled: boolean; // TODO remove after #refaktor

/**
 * @param enabled temporary mechanism to propagate information whether it should be used instead of properties and wallet clients
 */
export function initCoreClient(endpoint: string, enabled: boolean): void {
  coreGQLEndpoint = endpoint;
  coreApiEnabled = enabled;
}

/**
 * Returns generated SDK for core microservice.
 * For instructions how to regenerate the SDK see README in the project root.
 * More info: https://graphql-code-generator.com/docs/plugins/typescript-graphql-request
 *
 * TODO remove `enabled` flag later #refaktor
 */
export async function getCoreClient(): Promise<ReturnType<typeof getSdk> & { enabled: boolean }> {
  if (!coreGQLEndpoint) {
    throw new Error('Missing coreGQLEndpoint variable');
  }
  // TODO: Save created GraphQLClient into caches but keep in mind that we need to reset these cache externally.
  const sdk = await getSdk(
    await createGraphQLClient({ endpoint: coreGQLEndpoint!, authenticated: true }),
    sdkFunctionWrapper
  );
  return { ...sdk, enabled: coreApiEnabled };
}
