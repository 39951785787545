import { LegalEntityVerificationVersion, UserVerificationVersion, VeriffSessionStatus } from '../users';

export enum InvestorSuitabilityQuestionnaireSubmitResult {
  SUITABLE = 'suitable',
  UNSUITABLE = 'unsuitable',
}

/** This should be 1:1 copy of UserVerificationDataDTO in https://gitlab.com/investown/investown/-/blob/master/apps/users/component/userVerification/model.ts */
export interface UserVerificationDataDTO {
  pep?: PEPValue;
  pepFirstName?: string | null;
  pepLastName?: string | null;
  pepFunction?: string | null;
  pepOrganization?: string | null;
  pepRelationship?: string | null;
  pepCountry?: string | null;
  sofEmployment?: boolean;
  sofRent?: boolean;
  sofPension?: boolean;
  sofBusiness?: boolean;
  sofParentalLeave?: boolean;
  sofOther?: string | null;
  dateBirth?: string | null;
  personalIdentifier?: string | null;
  street?: string;
  city?: string;
  zip?: string;
  countryIsoCode?: string;
  contactStreet?: string | null;
  contactCity?: string | null;
  contactZip?: string | null;
  contactCountryIsoCode?: string | null;
  phoneNumber?: string;
  documentsCaptured?: boolean;
  submitted?: boolean;
  termsAndConditions?: boolean;
  finishedPhase?: number;
  version?: UserVerificationVersion;
  companyVerificationVersion?: LegalEntityVerificationVersion;
  suitability?: InvestorSuitabilityQuestionnaireSubmitResult | null;
  latestVeriffSessionStatus?: VeriffSessionStatus;
}

export interface UserVerificationDataResponse extends UserVerificationDataDTO {
  version: UserVerificationVersion;
  companyVerificationVersion: LegalEntityVerificationVersion;
}

export type UserVerificationDataForMigrationToV4 = Record<string, never>;

export enum PEPValue {
  No = 'no', // User is not PEP
  Yes = 'yes', // User is PEP himself
  Family = 'family', // User has family relation to PEP
  Colleague = 'colleague', // User has PEP colleague
}

export interface UploadPolicyDTO {
  policy: PresignedPost;
  keyStartsWith: string;
}

export interface VersionPreviewDTO {
  version: string;
  htmlPreview: string;
}

export interface FrameworkAgreementDTO {
  firstName: string;
  lastName: string;
  dateBirth?: string | null;
  personalIdentifier?: string | null;
  street: string;
  city: string;
  zip: string;
  variableSymbol: string;
}

export interface SignedFrameworkAgreement {
  link: string;
}

/** @see https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-HTTPPOSTConstructPolicy.html */
export interface PresignedPost {
  /**
   * The URL that should be used as the action of the form.
   */
  url: string;

  /**
   * The fields that must be included as hidden inputs on the form.
   */
  fields: {
    /**
     * A base64-encoded policy detailing what constitutes an acceptable POST
     * upload. Composed of the conditions and expiration provided to
     * s3.createPresignedPost
     */
    Policy: string;

    /**
     * A hex-encoded HMAC of the POST policy, signed with the credentials
     * provided to the S3 client.
     */
    'X-Amz-Signature': string;

    /**
     * Additional keys that must be included in the form to be submitted. This
     * will include signature metadata as well as any fields provided to
     * s3.createPresignedPost
     */
    [key: string]: string;
  };
}
