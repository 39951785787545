import { useQuery } from 'react-query';
import Big from 'big.js';

import { QueryKeys } from '../enums';
import { queryStaleTimes } from '../reactQueryConfig';
import { getPortfolioStatistics, getWallet } from '../core';
import { WalletQuery } from '../wallet/__generated__/sdk';
import { PortfolioStatisticsQuery } from '../properties/__generated__/sdk';

interface LoadingCurrentWealthResult {
  wealthAmount: null;
  isLoading: true;
  isError: false;
  error: null;
}

interface ErrorCurrentWealthResult {
  wealthAmount: null;
  isLoading: false;
  isError: true;
  error: Error;
}

interface SuccessCurrentWealthResult {
  wealthAmount: number;
  isLoading: false;
  isError: false;
  error: null;
}

type CurrentWealthResult = LoadingCurrentWealthResult | ErrorCurrentWealthResult | SuccessCurrentWealthResult;

export const useCurrentWealth = (): CurrentWealthResult => {
  const wallet = useQuery<WalletQuery, Error>(QueryKeys.Wallet, getWallet, {
    staleTime: queryStaleTimes.tenMinutes,
  });
  const portfolio = useQuery<PortfolioStatisticsQuery, Error>(QueryKeys.PortfolioStatistics, getPortfolioStatistics, {
    staleTime: queryStaleTimes.tenMinutes,
  });

  if (wallet.isLoading || portfolio.isLoading || wallet.isIdle || portfolio.isIdle) {
    return {
      wealthAmount: null,
      isLoading: true,
      isError: false,
      error: null,
    };
  }

  if (wallet.isError) {
    return {
      wealthAmount: null,
      isLoading: false,
      isError: true,
      error: wallet.error,
    };
  }
  if (portfolio.isError) {
    return {
      wealthAmount: null,
      isLoading: false,
      isError: true,
      error: portfolio.error,
    };
  }

  const availableBalance = new Big(wallet.data.Wallet.availableBalance);
  const portfolioSize = new Big(portfolio.data.portfolioStatistics.portfolioSize);

  return {
    wealthAmount: availableBalance.plus(portfolioSize).toNumber(),
    isLoading: false,
    isError: false,
    error: null,
  };
};
