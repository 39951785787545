import { getWalletClient } from '../wallet';

import { BankAccountsQuery } from './__generated__/sdk';
import { getCoreClient } from './client';

export async function getUserBankAccounts(): Promise<BankAccountsQuery> {
  const walletClient = await getWalletClient();
  const coreClient = await getCoreClient();
  if (!coreClient.enabled) {
    const bankAccounts = await walletClient.BankAccounts();
    return {
      VerifiedBankAccounts: bankAccounts.VerifiedBankAccounts.map((bankAccount) => ({
        ...bankAccount,
        // eslint-disable-next-line no-underscore-dangle
        __typename: 'BankAccountObjectType', // we need to map it to correct type that matches `core` type
      })),
      // eslint-disable-next-line no-underscore-dangle
      __typename: bankAccounts.__typename,
    };
  }
  return coreClient.BankAccounts();
}
