import { getPropertiesClient } from '../properties';

import { SecondaryMarketItemsQuery } from './__generated__/sdk';
import { getCoreClient } from './client';
import { PageRequest, SortRequest } from './model';

export async function getSecondaryMarketItems({
  pageRequest,
  sortRequest,
  investmentRoundId,
}: {
  pageRequest: PageRequest;
  sortRequest?: SortRequest;
  investmentRoundId?: string;
}): Promise<SecondaryMarketItemsQuery> {
  const propertiesClient = await getPropertiesClient();
  const coreClient = await getCoreClient();
  const { page, perPage } = pageRequest;

  if (!coreClient.enabled) {
    return propertiesClient.SecondaryMarketItems({
      page,
      perPage,
      sortField: sortRequest?.sortField,
      sortOrder: sortRequest?.sortOrder,
      investmentRoundId,
    });
  }
  return coreClient.SecondaryMarketItems({
    page,
    perPage,
    sortField: sortRequest?.sortField,
    sortOrder: sortRequest?.sortOrder,
    investmentRoundId,
  });
}
