import React, { FC } from 'react';
import styled from 'styled-components';

import { TextColorName, WithChildren } from '@investown/fe/common-utils';

import { TypographyName } from 'styles/theme';

export interface TypographyProps extends WithChildren {
  variant: TypographyName;
  color?: TextColorName;
  numberOfLines?: number;
  noWrap?: boolean;
  isItalic?: boolean;
  isStrikeThrough?: boolean;
  align?: 'left' | 'center' | 'right';
}

const Typography: FC<TypographyProps> = ({
  variant,
  color,
  numberOfLines,
  noWrap,
  isItalic,
  isStrikeThrough,
  children,
  align,
}: TypographyProps) => (
  <TypographyElement
    color={color}
    variant={variant}
    numberOfLines={numberOfLines}
    noWrap={noWrap}
    isItalic={isItalic}
    isStrikeThrough={isStrikeThrough}
    align={align}
  >
    {children}
  </TypographyElement>
);

const TypographyElement = styled.span<
  Pick<TypographyProps, 'color' | 'variant' | 'numberOfLines' | 'noWrap' | 'isItalic' | 'isStrikeThrough' | 'align'>
>`
  color: ${({ theme, color }) => (color ? theme.colorTokens.text[color] : 'unset')};
  font-size: ${({ theme, variant }) => theme.typography[variant].size};
  font-weight: ${({ theme, variant }) => theme.typography[variant].weight};
  line-height: ${({ theme, variant }) => theme.typography[variant].lineHeight};
  ${({ numberOfLines }) =>
    numberOfLines &&
    `
    display: -webkit-box;
    -webkit-line-clamp: ${numberOfLines};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
  ${({ noWrap }) =>
    noWrap &&
    `
    white-space: nowrap;
  `}
  ${({ isItalic }) =>
    isItalic &&
    `
    font-style: italic;
  `}
  ${({ isStrikeThrough }) =>
    isStrikeThrough &&
    `
  text-decoration-line: line-through;
  text-decoration-style: solid
  `};
  ${({ align }) =>
    align &&
    `
  text-align: ${align};
  `};
`;

export default Typography;
