import { getPropertiesClient } from '../properties';

import { InvestedPropertiesFilterType, InvestedPropertiesQuery } from './__generated__/sdk';
import { getCoreClient } from './client';

export interface InvestedPropertiesQueryProps {
  page: number;
  perPage: number;
  sortField: string;
  sortOrder: string;
  filter?: InvestedPropertiesFilterType | undefined;
}

export async function getInvestedProperties({
  page,
  perPage,
  sortField,
  sortOrder,
  filter,
}: InvestedPropertiesQueryProps): Promise<InvestedPropertiesQuery> {
  const propertiesClient = await getPropertiesClient();
  const coreClient = await getCoreClient();

  if (!coreClient.enabled) {
    const query = await propertiesClient.InvestedPropertiesV2({
      page,
      perPage,
      sortField,
      sortOrder,
      filter,
    });
    return {
      ...query,
      investedProperties: query.investedPropertiesV2,
    };
  }
  return coreClient.InvestedProperties({
    page,
    perPage,
    sortField,
    sortOrder,
    filter,
  });
}
