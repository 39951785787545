import { getPropertiesClient } from '../properties';

import { InvestmentRoundSecondaryMarketInfoQuery } from './__generated__/sdk';
import { getCoreClient } from './client';

export async function getInvestmentRoundSecondaryMarketInfo(
  investmentRoundId: string
): Promise<InvestmentRoundSecondaryMarketInfoQuery> {
  const propertiesClient = await getPropertiesClient();
  const coreClient = await getCoreClient();
  if (!coreClient.enabled) {
    return propertiesClient.InvestmentRoundSecondaryMarketInfo({ investmentRoundId });
  }
  return coreClient.InvestmentRoundSecondaryMarketInfo({ investmentRoundId });
}
