export type InvestorQuestionnaireLang = 'cs' | 'en';

export interface AbilityToBearLossBooleanQuestion {
  type: InvestorQuestionnaireQuestionType.BooleanInput;
  value?: boolean;
}

export interface InvestorQuestionnaireNumberInputQuestion {
  type: InvestorQuestionnaireQuestionType.NumberInput;
  min: number;
  max: number;
  defaultValue?: number;
  unit: string;
  score: number;
}

export interface InvestorQuestionnaireQuestionChoice {
  text: string;
  id: string;
  score: number;
}

export interface InvestorQuestionnaireSubQuestionChoice {
  text: string;
  id: string;
  choices: InvestorQuestionnaireQuestionChoice[];
}

export interface InvestorQuestionnaireTextChoiceQuestion {
  type: InvestorQuestionnaireQuestionType.SingleChoice | InvestorQuestionnaireQuestionType.MultipleChoice;
  choices: InvestorQuestionnaireQuestionChoice[];
}

export interface InvestorQuestionnaireTextMultipleChoiceSubQuestionsQuestion {
  type: InvestorQuestionnaireQuestionType.MultipleChoiceSubQuestions;
  subQuestions: InvestorQuestionnaireSubQuestionChoice[];
}

export type QuestionConfig =
  | InvestorQuestionnaireNumberInputQuestion
  | InvestorQuestionnaireTextChoiceQuestion
  | InvestorQuestionnaireTextMultipleChoiceSubQuestionsQuestion
  | AbilityToBearLossBooleanQuestion;

export interface InvestorQuestionnaireQuestion {
  id: string;
  title?: string;
  description?: string[];
  questionText: string;
  tooltipText: string;
  questionConfig: QuestionConfig;
}

export interface InvestorQuestionnaire {
  version: number;
  questions: InvestorQuestionnaireQuestion[];
}

export enum InvestorQuestionnaireQuestionType {
  NumberInput = 'NUMBER_INPUT',
  SingleChoice = 'SINGLE_CHOICE',
  MultipleChoice = 'MULTIPLE_CHOICE',
  MultipleChoiceSubQuestions = 'MULTIPLE_CHOICE_SUB_QUESTIONS',
  BooleanInput = 'BOOLEAN_INPUT',
}

export interface InvestorQuestionnaireLanguageMutationsResponse {
  en: string;
  cs: string;
}

export interface AbilityToBearLossBooleanQuestionQuestionResponse {
  type: InvestorQuestionnaireQuestionType.BooleanInput;
  value: boolean;
  subQuestions?: InvestorQuestionnaireQuestionMultipleChoiceResponse[];
}

export interface InvestorQuestionnaireNumberInputQuestionResponse {
  type: InvestorQuestionnaireQuestionType.NumberInput;
  min: number;
  max: number;
  defaultValue: number;
  unit: InvestorQuestionnaireLanguageMutationsResponse;
  score: number;
}

export interface InvestorQuestionnaireQuestionChoiceResponse {
  text: InvestorQuestionnaireLanguageMutationsResponse;
  id: string;
  score: number;
}

export interface InvestorQuestionnaireQuestionMultipleChoiceResponse {
  text: InvestorQuestionnaireLanguageMutationsResponse;
  id: string;
  choices: InvestorQuestionnaireQuestionChoiceResponse[];
}

export interface InvestorQuestionnaireTextChoiceQuestionResponse {
  type: InvestorQuestionnaireQuestionType.SingleChoice | InvestorQuestionnaireQuestionType.MultipleChoice;
  choices: InvestorQuestionnaireQuestionChoiceResponse[];
}

export interface InvestorQuestionnaireTextMultipleChoiceSubQuestionsQuestionResponse {
  type: InvestorQuestionnaireQuestionType.MultipleChoiceSubQuestions;
  subQuestions: InvestorQuestionnaireQuestionMultipleChoiceResponse[];
}

export interface InvestorQuestionnaireQuestionResponse {
  id: string;
  title: InvestorQuestionnaireLanguageMutationsResponse;
  description: InvestorQuestionnaireLanguageMutationsResponse[];
  questionText: InvestorQuestionnaireLanguageMutationsResponse;
  tooltipText: InvestorQuestionnaireLanguageMutationsResponse;
  questionConfig:
    | InvestorQuestionnaireNumberInputQuestionResponse
    | InvestorQuestionnaireTextChoiceQuestionResponse
    | InvestorQuestionnaireTextMultipleChoiceSubQuestionsQuestionResponse;
}

export interface InvestorQuestionnaireResponse {
  version: number;
  questions: InvestorQuestionnaireQuestionResponse[];
}

export interface BooleanAnswer {
  value: boolean;
}
export interface NumberAnswer {
  value: number;
}

export interface TextAnswer {
  answerId: string;
}

export interface MultiChoiceAnswer {
  choices: TextAnswer[];
}

export interface MultiChoiceSubQuestionsTextAnswer extends TextAnswer {
  subQuestionId: string;
}

export interface MultiChoiceSubQuestionsAnswer {
  subQuestions: MultiChoiceSubQuestionsTextAnswer[];
}

export type InvestorQuestionnaireAnswer = BooleanAnswer | NumberAnswer | TextAnswer | MultiChoiceAnswer;

export interface InvestorQuestionnaireSubmitQuestionAnswer {
  questionId: string;
  type: InvestorQuestionnaireQuestionType;
  answer: InvestorQuestionnaireAnswer;
}

export interface InvestorQuestionnaireSubmitDTO {
  version: number;
  answers: InvestorQuestionnaireSubmitQuestionAnswer[];
}

export interface AbilityToBearLossQuestionnaireSubmitDTO {
  consent: boolean;
  submittedQuestionnaire: InvestorQuestionnaireSubmitDTO;
}

export type AbilityToBearLossQuestionnaireSubmitResponseData =
  | { liquidAssets: number; regularAnnualExpenditure: number; totalIncome: number }
  | undefined;

export enum InvestorQuestionnaireSubmitResult {
  SUCCESS = 'SUCCESS',
  SUCCESS_WITH_WARNING = 'SUCCESS_WITH_WARNING',
  SUCCESS_WITH_OVERRIDE = 'SUCCESS_WITH_OVERRIDE',
  SUITABLE = 'suitable', // lowercase on BE
  UNSUITABLE = 'unsuitable',
}

export interface InvestorQuestionnaireSubmitResponse {
  status: InvestorQuestionnaireSubmitResult;
}

export interface UseInvestorQuestionnaireProps {
  language: InvestorQuestionnaireLang;
  isLegalEntity?: boolean;
  suitability?: boolean;
  submitGaEvent?: (payload: SubmitAnswerPayloadData) => void;
}

interface UseInvestorQuestionnaireDefinitionLoading {
  isDefinitionLoading: true;
  isSubmitLoading: false;
  submitResponseData: undefined;
}

export interface SubmitAnswerPayloadData {
  questionId: string;
  answer: string | number | boolean;
  isGoingBack?: boolean;
}

export interface SubmitAnswerPayload {
  questionId: string;
  type: InvestorQuestionnaireQuestionType;
  answer: InvestorQuestionnaireAnswer;
}

export type SubmitAnswer = (payload: SubmitAnswerPayload) => void;

export interface UseInvestorQuestionnaireNotLoading {
  progress: number;
  isDefinitionLoading: false;
  isSubmitLoading: false;
  questionnaire: InvestorQuestionnaireQuestion[];
  actualQuestion: InvestorQuestionnaireQuestion;
  numberOfQuestions: number;
  submitAnswer: SubmitAnswer;
  goBack: () => void;
  submitResponseData?: InvestorQuestionnaireSubmitResponse;
}

interface UseInvestorQuestionnaireSubmitLoading {
  progress: number;
  isDefinitionLoading: false;
  isSubmitLoading: true;
  questionnaire: InvestorQuestionnaireQuestion[];
  actualQuestion: InvestorQuestionnaireQuestion;
  numberOfQuestions: number;
  submitAnswer: SubmitAnswer;
  goBack: () => void;
  submitResponseData: undefined;
}

export type UseInvestorQuestionnaire =
  | UseInvestorQuestionnaireDefinitionLoading
  | UseInvestorQuestionnaireNotLoading
  | UseInvestorQuestionnaireSubmitLoading;
