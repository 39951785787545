import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';

import * as PropertiesSDK from '../properties/__generated__/sdk';
import * as CoreSDK from '../core/__generated__/sdk';
import { QueryKeys } from '../enums';
import { PageRequest, SortRequest } from '../properties';
import * as WalletSDK from '../wallet/__generated__/sdk';
import { getInvestedProperties } from '../core';

export enum SortBy {
  CreatedAt = 'createdAt',
  InvestedAmount = 'investedAmount',
  AnnualYield = 'annualYield',
  InvestmentLengthInMonths = 'investmentLengthInMonths',
  InterestAmount = 'interestAmount',
  TimeLeft = 'timeLeft',
  PayDay = 'installmentDueDate',
}

export enum FilterBy {
  Regular = PropertiesSDK.PropertyInvestmentRoundRepaymentStatus.Regular,
  Collection = PropertiesSDK.PropertyInvestmentRoundRepaymentStatus.Collection,
  Delayed = PropertiesSDK.PropertyInvestmentRoundRepaymentStatus.Delayed,
  Open = PropertiesSDK.PropertyInvestmentRoundStatus.Open,
  Processing = PropertiesSDK.PropertyInvestmentRoundStatus.FullyInvested,
  Completed = PropertiesSDK.PropertyInvestmentRoundStatus.Ended,
}

export type FilterItem = {
  key: FilterBy;
  type: keyof PropertiesSDK.InvestedPropertiesFilterType;
  value: FilterBy;
  label: { value: string };
};

export type InvestedProject = PropertiesSDK.InvestedPropertyObjectType & {
  returns?: WalletSDK.AmountWithCurrencyObjectType;
};

export type RelatedProject = PropertiesSDK.RelatedPropertyObjectType & {
  returns?: WalletSDK.AmountWithCurrencyObjectType;
};

export type ProjectDetailedItemType = InvestedProject | RelatedProject;

type QueryOptions = Parameters<
  typeof useInfiniteQuery<
    CoreSDK.InvestedPropertiesQuery, // it uses still Properties API under the hood but type must be already from CoreSDK #refaktor
    Error,
    CoreSDK.InvestedPropertiesQuery,
    [string, PageRequest & SortRequest & { filter: PropertiesSDK.InvestedPropertiesFilterType | undefined }]
  >
>[2];

type RequestParams = PageRequest & SortRequest & { filter: PropertiesSDK.InvestedPropertiesFilterType | undefined };

export function useInvestedProperties(
  {
    page = 0,
    perPage = 10,
    sortField = 'createdAt',
    sortOrder = 'ASC',
    filter,
  }: {
    page?: number;
    perPage?: number;
    sortField?: string;
    sortOrder?: 'ASC' | 'DESC';
    filter?: PropertiesSDK.InvestedPropertiesFilterType | undefined;
  } = {},
  queryOptions?: QueryOptions & { onBeforeRequest?: (params: RequestParams) => void }
): UseInfiniteQueryResult<CoreSDK.InvestedPropertiesQuery, Error> {
  // it uses still Properties API under the hood but type must be already from CoreSDK #refaktor
  return useInfiniteQuery<
    CoreSDK.InvestedPropertiesQuery,
    Error,
    CoreSDK.InvestedPropertiesQuery,
    [string, PageRequest & SortRequest & { filter: CoreSDK.InvestedPropertiesFilterType | undefined }]
  >(
    [QueryKeys.InvestedProperties, { page, perPage, sortField, sortOrder, filter }],
    ({ pageParam = page }) => {
      const queryParams = {
        page: pageParam,
        perPage,
        sortField,
        sortOrder,
        filter,
      };
      if (queryOptions?.onBeforeRequest && typeof queryOptions.onBeforeRequest === 'function') {
        queryOptions.onBeforeRequest(queryParams);
      }
      return getInvestedProperties(queryParams);
    },
    {
      getNextPageParam: (lastPage, pages) => {
        return lastPage.investedProperties.length < perPage ? undefined : pages.length;
      },
      staleTime: 0,
      ...queryOptions,
    }
  );
}

export function useInvestedPropertiesCount(): number {
  const { data } = useInvestedProperties(
    {
      page: 0,
      perPage: 1,
    },
    {
      notifyOnChangeProps: ['data'],
      refetchOnMount: 'always',
    }
  );

  try {
    if (!data) {
      return 0;
    }

    return data.pages[0].total.count;
  } catch (e) {
    return 0;
  }
}
