import { getPropertiesClient } from '../properties';

import {
  PageablePropertiesQuery,
  PropertyInvestmentRoundLevel,
  PropertyInvestmentRoundStatus,
  PropertyInvestmentRoundType,
} from './__generated__/sdk';
import { PageRequest, SortRequest } from './model';
import { getCoreClient } from './client';

export interface Filter {
  currentUsersTotalInvestmentGreaterThan?: number;
  investmentAmountAvailableEqualTo?: number;
  investmentAmountAvailableGreaterThan?: number;
  levelIn?: PropertyInvestmentRoundLevel[];
  typeIn?: PropertyInvestmentRoundType[];
  statusIn?: PropertyInvestmentRoundStatus[];
}

/**
 * Maps UI list filter/tab to desired round statuses.
 */
export function mapPropertiesListStatusToRoundStatuses(
  listStatus: 'open' | 'full' | 'secondary' | 'invested'
): PropertyInvestmentRoundStatus[] {
  switch (listStatus) {
    case 'open':
      return [PropertyInvestmentRoundStatus.Open];
    case 'full':
      return [
        PropertyInvestmentRoundStatus.Unknown,
        PropertyInvestmentRoundStatus.FullyInvested,
        PropertyInvestmentRoundStatus.Funded,
        PropertyInvestmentRoundStatus.Ended,
      ];
    case 'invested':
      return [
        PropertyInvestmentRoundStatus.Unknown,
        PropertyInvestmentRoundStatus.Open,
        PropertyInvestmentRoundStatus.FullyInvested,
        PropertyInvestmentRoundStatus.Funded,
        PropertyInvestmentRoundStatus.Ended,
      ];
    case 'secondary':
      return [PropertyInvestmentRoundStatus.Funded];
    default:
      throw new Error(`Unexpected listType "${listStatus}"`);
  }
}

export async function getPageOfProperties({
  pageRequest,
  filter,
  sortRequest,
}: {
  pageRequest: PageRequest;
  filter: Filter;
  sortRequest?: SortRequest;
}): Promise<PageablePropertiesQuery> {
  const propertiesClient = await getPropertiesClient();
  const coreClient = await getCoreClient();
  const { page, perPage } = pageRequest;
  const {
    currentUsersTotalInvestmentGreaterThan,
    investmentAmountAvailableEqualTo,
    investmentAmountAvailableGreaterThan,
    levelIn,
    typeIn,
    statusIn,
  } = filter;

  if (!coreClient.enabled) {
    return propertiesClient.PageableProperties({
      page,
      perPage,
      sortField: sortRequest?.sortField,
      sortOrder: sortRequest?.sortOrder,
      currentUsersTotalInvestment_gt: currentUsersTotalInvestmentGreaterThan,
      investmentAmountAvailable_eq: investmentAmountAvailableEqualTo,
      investmentAmountAvailable_gt: investmentAmountAvailableGreaterThan,
      level_in: levelIn,
      type_in: typeIn,
      status_in: statusIn,
    });
  }
  return coreClient.PageableProperties({
    page,
    perPage,
    sortField: sortRequest?.sortField,
    sortOrder: sortRequest?.sortOrder,
    currentUsersTotalInvestment_gt: currentUsersTotalInvestmentGreaterThan,
    investmentAmountAvailable_eq: investmentAmountAvailableEqualTo,
    investmentAmountAvailable_gt: investmentAmountAvailableGreaterThan,
    level_in: levelIn,
    type_in: typeIn,
    status_in: statusIn,
  });
}

export const isProjectExpired = (status: PropertyInvestmentRoundStatus | undefined): boolean => {
  const isExpired =
    status === PropertyInvestmentRoundStatus.Ended || status === PropertyInvestmentRoundStatus.NotFunded;
  return isExpired;
};

export const isProjectOpen = (status: PropertyInvestmentRoundStatus | undefined): boolean =>
  status === PropertyInvestmentRoundStatus.Open;
