import { getPropertiesClient } from '../properties';

import { CountSecondaryMarketBidsQuery } from './__generated__/sdk';
import { getCoreClient } from './client';

export async function getCountSecondaryMarketBids(): Promise<CountSecondaryMarketBidsQuery> {
  const propertiesClient = await getPropertiesClient();
  const coreClient = await getCoreClient();
  if (!coreClient.enabled) {
    return propertiesClient.CountSecondaryMarketBids();
  }
  return coreClient.CountSecondaryMarketBids();
}
