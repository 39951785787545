import { useQuery } from 'react-query';

import { QueryKeys } from '../enums';
import { getInvestments } from '../core';
import { InvestmentObjectType, InvestmentsQuery } from '../properties/__generated__/sdk';
import { queryStaleTimes } from '../reactQueryConfig';

type LoadingResult = {
  status: 'loading';
  error: undefined;
  investments: undefined;
};

type ErrorResult = {
  status: 'error';
  error: Error;
  investments: undefined;
};

type SuccessResult = {
  status: 'success';
  error: undefined;
  investments: {
    data: InvestmentObjectType[];
    canBeAnyWithdrawn: boolean;
  };
};

export type Result = LoadingResult | SuccessResult | ErrorResult;

export const usePropertyInvestments = (slug: string): Result => {
  const queryResult = useQuery<InvestmentsQuery, Error, InvestmentsQuery, [string, string]>(
    [QueryKeys.PropertyInvestments, slug],
    () => getInvestments(slug),
    { refetchInterval: queryStaleTimes.thirtySeconds }
  );

  if (queryResult.isLoading || queryResult.isIdle) {
    return {
      status: 'loading',
      error: undefined,
      investments: undefined,
    };
  }

  if (queryResult.isError) {
    return {
      status: 'error',
      error: queryResult.error,
      investments: undefined,
    };
  }

  return {
    status: 'success',
    error: undefined,
    investments: {
      data: queryResult.data.investments,
      canBeAnyWithdrawn: queryResult.data.investments.some(
        (investment) => investment.canBeWithdrawn && !investment.withdrawn
      ),
    },
  };
};
