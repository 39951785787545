import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The javascript `Date` as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
  Timestamp: any;
};

/** Amount with currency */
export type AmountWithCurrencyObjectType = {
  __typename?: 'AmountWithCurrencyObjectType';
  amount: Scalars['Float'];
  /** ISO 4217 currency code (e.g. "CZK") */
  currency: Scalars['String'];
};

export type BankAccountType = {
  __typename?: 'BankAccountType';
  id: Scalars['String'];
  /** Flag is bank account set as primary/default */
  isDefault: Scalars['Boolean'];
  /** Flag is verified by at least one incoming payment */
  isVerified: Scalars['Boolean'];
  /** Bank account number first part. Only for Czech account. */
  accountNumber?: Maybe<Scalars['String']>;
  /** 4 digits czech bank code. Only for Czech account. */
  bankCode?: Maybe<Scalars['String']>;
  iban: Scalars['String'];
  /** Bank account holder name */
  holderName: Scalars['String'];
};

export type CreateUsersFinancialPlanInputType = {
  type: FinancialPlanType;
  /** One-time investment made right after creation of the plan */
  initialInvestment: PositiveIntegerMonetaryAmountInputType;
  /** Investment made monthly on regular basis */
  monthlyContribution: PositiveIntegerMonetaryAmountInputType;
  /** Annual percentage yield at time of creation of this plan */
  initialAnnualPercentageYield: Scalars['Float'];
  /** Is required only when the plan is of type `FinancialPlanType.WITH_SAVINGS_GOAL`. */
  savingsGoalAmount?: Maybe<PositiveIntegerMonetaryAmountInputType>;
  /** Is required only when the plan is of type `FinancialPlanType.WITH_SAVINGS_GOAL`. */
  goalType?: Maybe<FinancialPlanGoalType>;
  /** Is required only when the plan is of type `FinancialPlanType.WITH_SAVINGS_GOAL`. */
  terminationDate?: Maybe<Scalars['DateTime']>;
};

export enum FinancialPlanGoalType {
  Mortgage = 'MORTGAGE',
  Car = 'CAR',
  Education = 'EDUCATION',
  Retirement = 'RETIREMENT',
  Other = 'OTHER',
}

export enum FinancialPlanStatus {
  Draft = 'DRAFT',
  Finished = 'FINISHED',
}

export enum FinancialPlanType {
  Basic = 'BASIC',
  WithSavingsGoal = 'WITH_SAVINGS_GOAL',
}

/** Monetary amount value and currency */
export type FloatMonetaryAmountObjectType = {
  __typename?: 'FloatMonetaryAmountObjectType';
  value: Scalars['Float'];
  /** ISO 4217 currency code (e.g. "CZK") */
  currency: Scalars['String'];
};

export type InternationalTransactionWithdrawalFee = {
  __typename?: 'InternationalTransactionWithdrawalFee';
  min: AmountWithCurrencyObjectType;
  max: AmountWithCurrencyObjectType;
};

/** Describes user's total returns from a particular investment round. */
export type InvestmentRoundReturnsObjectType = {
  __typename?: 'InvestmentRoundReturnsObjectType';
  /** Investment round id */
  investmentRoundId: Scalars['ID'];
  /** Sum of all returns from the invested project (crowdfunding only) */
  totalReturns: AmountWithCurrencyObjectType;
};

export type ListMetadata = {
  __typename?: 'ListMetadata';
  count: Scalars['Int'];
};

/** User's profit in a respective month in history or present */
export type MonthlyProfitObjectType = {
  __typename?: 'MonthlyProfitObjectType';
  year: Scalars['Int'];
  month: Scalars['Int'];
  /** User's profit expressed in a specific currency */
  profit: AmountWithCurrencyObjectType;
  /** User's yield expressed in a specific currency */
  yield: AmountWithCurrencyObjectType;
  /** User's loss expressed in a specific currency */
  loss: AmountWithCurrencyObjectType;
  /** User's received level bonus yield expressed in a specific currency. Also included in the 'yield' field! */
  userLevelBonusYield: AmountWithCurrencyObjectType;
  /** User's bonuses and other incomes expressed in a specific currency. Does not include level bonus yield. */
  bonusesAndOtherIncome: AmountWithCurrencyObjectType;
};

export type MonthlyReturnsObjectType = {
  __typename?: 'MonthlyReturnsObjectType';
  /** Number representing year */
  year: Scalars['Int'];
  /** Number representing month in year (1-12) */
  month: Scalars['Int'];
  rentReturns: AmountWithCurrencyObjectType;
};

export type Mutation = {
  __typename?: 'Mutation';
  exportWalletTransactionsToCSV: Scalars['Boolean'];
  createUsersFinancialPlan: UsersFinancialPlanObjectType;
  updateUsersFinancialPlan: UsersFinancialPlanObjectType;
  finishUsersFinancialPlan: UsersFinancialPlanObjectType;
};

export type MutationCreateUsersFinancialPlanArgs = {
  input: CreateUsersFinancialPlanInputType;
};

export type MutationUpdateUsersFinancialPlanArgs = {
  input: UpdateUsersFinancialPlanInputType;
  id: Scalars['ID'];
};

export type MutationFinishUsersFinancialPlanArgs = {
  id: Scalars['ID'];
};

/** Monetary amount input value and currency */
export type PositiveIntegerMonetaryAmountInputType = {
  value: Scalars['Int'];
  /** ISO 4217 currency code (e.g. "CZK") */
  currency: Scalars['String'];
};

export type ProjectInfo = {
  __typename?: 'ProjectInfo';
  /** Is returned in respective language or in Czech if not specified. */
  name: Scalars['String'];
  /** Can be used to build link to project detail. */
  slug: Scalars['String'];
};

/** Describes user's total returns from a particular property. */
export type PropertyReturnsObjectType = {
  __typename?: 'PropertyReturnsObjectType';
  /** Property id */
  propertyId: Scalars['ID'];
  /** Sum of all returns (e.g. rent) from the property */
  totalReturns: AmountWithCurrencyObjectType;
};

export type Query = {
  __typename?: 'Query';
  /** Information about user's wallet. */
  Wallet: WalletObjectType;
  MonthlyGrossReturns: Array<MonthlyReturnsObjectType>;
  TotalProfit: AmountWithCurrencyObjectType;
  UserInvestmentStats: UserInvestmentStatsType;
  TotalUserLevelBonusYield: AmountWithCurrencyObjectType;
  /** User's profit in a respective month in history or present */
  profitsMonthlyHistory: Array<MonthlyProfitObjectType>;
  /** How many months to the history has the user profits (including current month) */
  _profitsMonthlyHistoryMeta: ListMetadata;
  /** Returns user's paged wallet history. */
  walletHistory: Array<WalletTransactionObjectType>;
  _walletHistoryMeta: ListMetadata;
  /** Get user verified bank accounts */
  VerifiedBankAccounts: Array<BankAccountType>;
  /** Returns of all historically invested properties (participations only) */
  allPropertyReturns: Array<PropertyReturnsObjectType>;
  /** Returns of all historically invested projects (crowdfunding only) */
  allCrowdfundingReturns: Array<InvestmentRoundReturnsObjectType>;
  /** All user's financial plans. */
  myFinancialPlans: Array<UsersFinancialPlanObjectType>;
  /** Returns the withdrawal fee computed amount for the user. */
  userWithdrawalFees: WithdrawalFeesObjectType;
};

export type QueryMonthlyGrossReturnsArgs = {
  futureMonths?: Maybe<Scalars['Int']>;
  pastMonths?: Maybe<Scalars['Int']>;
};

export type QueryUserInvestmentStatsArgs = {
  input: UserInvestmentStatsInputType;
};

export type QueryTotalUserLevelBonusYieldArgs = {
  input: TotalUserLevelBonusYieldInputType;
};

export type QueryProfitsMonthlyHistoryArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

export type QueryWalletHistoryArgs = {
  filter?: Maybe<WalletTransactionFilterType>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
};

export type Query_WalletHistoryMetaArgs = {
  filter?: Maybe<WalletTransactionFilterType>;
};

export type QueryUserWithdrawalFeesArgs = {
  bankAccountId: Scalars['String'];
  amount: Scalars['Float'];
};

export type TotalUserLevelBonusYieldInputType = {
  propertyInvestmentRoundId?: Maybe<Scalars['ID']>;
  onlyCurrentMonth?: Maybe<Scalars['Boolean']>;
};

export type UpdateUsersFinancialPlanInputType = {
  type: FinancialPlanType;
  /** One-time investment made right after creation of the plan */
  initialInvestment: PositiveIntegerMonetaryAmountInputType;
  /** Investment made monthly on regular basis */
  monthlyContribution: PositiveIntegerMonetaryAmountInputType;
  /** Annual percentage yield at time of creation or update of this plan */
  initialAnnualPercentageYield: Scalars['Float'];
  /** Is required only when the plan is of type `FinancialPlanType.WITH_SAVINGS_GOAL`. */
  savingsGoalAmount?: Maybe<PositiveIntegerMonetaryAmountInputType>;
  /** Is required only when the plan is of type `FinancialPlanType.WITH_SAVINGS_GOAL`. */
  goalType?: Maybe<FinancialPlanGoalType>;
  /** Is required only when the plan is of type `FinancialPlanType.WITH_SAVINGS_GOAL`. */
  terminationDate?: Maybe<Scalars['DateTime']>;
};

export type UserInvestmentStatsInputType = {
  currency: Scalars['String'];
};

export type UserInvestmentStatsType = {
  __typename?: 'UserInvestmentStatsType';
  avgInvestedAmount: FloatMonetaryAmountObjectType;
  numberOfInvestments: Scalars['Int'];
  lastInvestmentAt?: Maybe<Scalars['Timestamp']>;
};

export type UsersFinancialPlanObjectType = {
  __typename?: 'UsersFinancialPlanObjectType';
  id: Scalars['String'];
  /** Date of creation */
  createdAt: Scalars['DateTime'];
  /** Date of the last update */
  updatedAt: Scalars['DateTime'];
  type: FinancialPlanType;
  status: FinancialPlanStatus;
  /** One-time investment made right after creation of the plan */
  initialInvestment: AmountWithCurrencyObjectType;
  /** Investment made monthly on regular basis */
  monthlyContribution: AmountWithCurrencyObjectType;
  /** Annual percentage yield at time of creation of this plan */
  initialAnnualPercentageYield: Scalars['Float'];
  /** Is present only when the plan is of type `FinancialPlanType.WITH_SAVINGS_GOAL`. */
  savingsGoalAmount?: Maybe<AmountWithCurrencyObjectType>;
  /** Is present only when the plan is of type `FinancialPlanType.WITH_SAVINGS_GOAL`. */
  goalType?: Maybe<FinancialPlanGoalType>;
  /** Is present only when the plan is of type `FinancialPlanType.WITH_SAVINGS_GOAL`. */
  terminationDate?: Maybe<Scalars['DateTime']>;
};

export type WalletObjectType = {
  __typename?: 'WalletObjectType';
  /** User's unique payment reference. */
  variableSymbol: Scalars['String'];
  availableBalance: Scalars['Float'];
};

export type WalletTransactionFilterType = {
  /** Filter by transaction types */
  allowedTypes?: Maybe<Array<WalletTransactionType>>;
};

export type WalletTransactionObjectType = {
  __typename?: 'WalletTransactionObjectType';
  /** Date of the transaction */
  createdAt: Scalars['Timestamp'];
  type: WalletTransactionType;
  amount: Scalars['Float'];
  currency: Scalars['String'];
  /** Nullable as some transaction are not related to any project. */
  projectInfo?: Maybe<ProjectInfo>;
};

export enum WalletTransactionType {
  WalletDeposit = 'WALLET_DEPOSIT',
  WalletWithdrawal = 'WALLET_WITHDRAWAL',
  PropertyRentUserIncome = 'PROPERTY_RENT_USER_INCOME',
  LoanInterestPaymentUserIncome = 'LOAN_INTEREST_PAYMENT_USER_INCOME',
  LateInterestFeePaymentUserIncome = 'LATE_INTEREST_FEE_PAYMENT_USER_INCOME',
  StatutoryLateInterestFeePaymentUserIncome = 'STATUTORY_LATE_INTEREST_FEE_PAYMENT_USER_INCOME',
  InvestmentPrimaryBuy = 'INVESTMENT_PRIMARY_BUY',
  InvestmentSecondaryBuy = 'INVESTMENT_SECONDARY_BUY',
  InvestmentMixedBuy = 'INVESTMENT_MIXED_BUY',
  Refund = 'REFUND',
  PrincipalReturn = 'PRINCIPAL_RETURN',
  PartialPrincipalReturn = 'PARTIAL_PRINCIPAL_RETURN',
  WithdrawalFromInvestment = 'WITHDRAWAL_FROM_INVESTMENT',
  EarlySaleInvestmentFee = 'EARLY_SALE_INVESTMENT_FEE',
  WalletWithdrawalFee = 'WALLET_WITHDRAWAL_FEE',
  PurchaseOfferedInvestment = 'PURCHASE_OFFERED_INVESTMENT',
  PremiumBonusUserIncome = 'PREMIUM_BONUS_USER_INCOME',
  PremiumBonusCrowdfundingUserIncome = 'PREMIUM_BONUS_CROWDFUNDING_USER_INCOME',
  ReferralBonusForRefereeUserIncome = 'REFERRAL_BONUS_FOR_REFEREE_USER_INCOME',
  ReferralBonusForReferrerUserIncome = 'REFERRAL_BONUS_FOR_REFERRER_USER_INCOME',
  ReactivationBonusUserIncome = 'REACTIVATION_BONUS_USER_INCOME',
  BidBlockingSecondaryMarket = 'BID_BLOCKING_SECONDARY_MARKET',
  BidBlockingSecondaryMarketRevert = 'BID_BLOCKING_SECONDARY_MARKET_REVERT',
  InheritanceTransfer = 'INHERITANCE_TRANSFER',
  LevelBonusYieldUserIncome = 'LEVEL_BONUS_YIELD_USER_INCOME',
}

export type WithdrawalFeesObjectType = {
  __typename?: 'WithdrawalFeesObjectType';
  /** Base part of fee is billed only if the user is withdrawing more than once in last x hours (where x is defined in config as feeLessWalletWithdrawalAfterHours). */
  baseWithdrawalFee: AmountWithCurrencyObjectType;
  /** This part is billed only if the user is withdrawing to non czech iban account. This part is only a range and is handled manually. */
  internationalTransactionWithdrawalFee?: Maybe<InternationalTransactionWithdrawalFee>;
};

export type CreateUsersFinancialPlanMutationVariables = Exact<{
  input: CreateUsersFinancialPlanInputType;
}>;

export type CreateUsersFinancialPlanMutation = { __typename?: 'Mutation' } & {
  createUsersFinancialPlan: { __typename?: 'UsersFinancialPlanObjectType' } & Pick<
    UsersFinancialPlanObjectType,
    'id' | 'createdAt' | 'status'
  >;
};

export type ExportWalletTransactionsToCsvMutationVariables = Exact<{ [key: string]: never }>;

export type ExportWalletTransactionsToCsvMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'exportWalletTransactionsToCSV'
>;

export type FinishUsersFinancialPlanMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type FinishUsersFinancialPlanMutation = { __typename?: 'Mutation' } & {
  finishUsersFinancialPlan: { __typename?: 'UsersFinancialPlanObjectType' } & Pick<UsersFinancialPlanObjectType, 'id'>;
};

export type UpdateUsersFinancialPlanMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateUsersFinancialPlanInputType;
}>;

export type UpdateUsersFinancialPlanMutation = { __typename?: 'Mutation' } & {
  updateUsersFinancialPlan: { __typename?: 'UsersFinancialPlanObjectType' } & Pick<
    UsersFinancialPlanObjectType,
    'id' | 'updatedAt' | 'status'
  >;
};

export type BankAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type BankAccountsQuery = { __typename?: 'Query' } & {
  VerifiedBankAccounts: Array<
    { __typename?: 'BankAccountType' } & Pick<
      BankAccountType,
      'id' | 'iban' | 'accountNumber' | 'bankCode' | 'isDefault'
    >
  >;
};

export type MonthlyGrossReturnsQueryVariables = Exact<{ [key: string]: never }>;

export type MonthlyGrossReturnsQuery = { __typename?: 'Query' } & {
  MonthlyGrossReturns: Array<
    { __typename?: 'MonthlyReturnsObjectType' } & Pick<MonthlyReturnsObjectType, 'year' | 'month'> & {
        rentReturns: { __typename?: 'AmountWithCurrencyObjectType' } & Pick<
          AmountWithCurrencyObjectType,
          'amount' | 'currency'
        >;
      }
  >;
};

export type MonthlyProfitQueryVariables = Exact<{
  page: Scalars['Int'];
  perPage: Scalars['Int'];
}>;

export type MonthlyProfitQuery = { __typename?: 'Query' } & {
  items: Array<
    { __typename?: 'MonthlyProfitObjectType' } & Pick<MonthlyProfitObjectType, 'year' | 'month'> & {
        profit: { __typename?: 'AmountWithCurrencyObjectType' } & Pick<
          AmountWithCurrencyObjectType,
          'amount' | 'currency'
        >;
        yield: { __typename?: 'AmountWithCurrencyObjectType' } & Pick<
          AmountWithCurrencyObjectType,
          'amount' | 'currency'
        >;
        loss: { __typename?: 'AmountWithCurrencyObjectType' } & Pick<
          AmountWithCurrencyObjectType,
          'amount' | 'currency'
        >;
        userLevelBonusYield: { __typename?: 'AmountWithCurrencyObjectType' } & Pick<
          AmountWithCurrencyObjectType,
          'amount' | 'currency'
        >;
        bonusesAndOtherIncome: { __typename?: 'AmountWithCurrencyObjectType' } & Pick<
          AmountWithCurrencyObjectType,
          'amount' | 'currency'
        >;
      }
  >;
  total: { __typename?: 'ListMetadata' } & Pick<ListMetadata, 'count'>;
};

export type MyFinancialPlansQueryVariables = Exact<{ [key: string]: never }>;

export type MyFinancialPlansQuery = { __typename?: 'Query' } & {
  myFinancialPlans: Array<
    { __typename?: 'UsersFinancialPlanObjectType' } & Pick<
      UsersFinancialPlanObjectType,
      | 'id'
      | 'createdAt'
      | 'updatedAt'
      | 'type'
      | 'status'
      | 'initialAnnualPercentageYield'
      | 'goalType'
      | 'terminationDate'
    > & {
        initialInvestment: { __typename?: 'AmountWithCurrencyObjectType' } & Pick<
          AmountWithCurrencyObjectType,
          'amount' | 'currency'
        >;
        monthlyContribution: { __typename?: 'AmountWithCurrencyObjectType' } & Pick<
          AmountWithCurrencyObjectType,
          'amount' | 'currency'
        >;
        savingsGoalAmount?: Maybe<
          { __typename?: 'AmountWithCurrencyObjectType' } & Pick<AmountWithCurrencyObjectType, 'amount' | 'currency'>
        >;
      }
  >;
};

export type TotalProfitQueryVariables = Exact<{ [key: string]: never }>;

export type TotalProfitQuery = { __typename?: 'Query' } & {
  TotalProfit: { __typename?: 'AmountWithCurrencyObjectType' } & Pick<
    AmountWithCurrencyObjectType,
    'amount' | 'currency'
  >;
};

export type TotalUserLevelBonusYieldQueryVariables = Exact<{
  input: TotalUserLevelBonusYieldInputType;
}>;

export type TotalUserLevelBonusYieldQuery = { __typename?: 'Query' } & {
  TotalUserLevelBonusYield: { __typename?: 'AmountWithCurrencyObjectType' } & Pick<
    AmountWithCurrencyObjectType,
    'amount' | 'currency'
  >;
};

export type UserInvestmentStatsQueryVariables = Exact<{
  input: UserInvestmentStatsInputType;
}>;

export type UserInvestmentStatsQuery = { __typename?: 'Query' } & {
  UserInvestmentStats: { __typename?: 'UserInvestmentStatsType' } & Pick<
    UserInvestmentStatsType,
    'numberOfInvestments' | 'lastInvestmentAt'
  > & {
      avgInvestedAmount: { __typename?: 'FloatMonetaryAmountObjectType' } & Pick<
        FloatMonetaryAmountObjectType,
        'value' | 'currency'
      >;
    };
};

export type UserWithdrawalFeesQueryVariables = Exact<{
  bankAccountId: Scalars['String'];
  amount: Scalars['Float'];
}>;

export type UserWithdrawalFeesQuery = { __typename?: 'Query' } & {
  userWithdrawalFees: { __typename?: 'WithdrawalFeesObjectType' } & {
    baseWithdrawalFee: { __typename?: 'AmountWithCurrencyObjectType' } & Pick<
      AmountWithCurrencyObjectType,
      'amount' | 'currency'
    >;
  };
};

export type WalletQueryVariables = Exact<{ [key: string]: never }>;

export type WalletQuery = { __typename?: 'Query' } & {
  Wallet: { __typename?: 'WalletObjectType' } & Pick<WalletObjectType, 'variableSymbol' | 'availableBalance'>;
};

export type WalletHistoryQueryVariables = Exact<{
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  sortField: Scalars['String'];
  sortOrder: Scalars['String'];
  filter: WalletTransactionFilterType;
}>;

export type WalletHistoryQuery = { __typename?: 'Query' } & {
  items: Array<
    { __typename?: 'WalletTransactionObjectType' } & Pick<
      WalletTransactionObjectType,
      'createdAt' | 'type' | 'amount' | 'currency'
    > & { projectInfo?: Maybe<{ __typename?: 'ProjectInfo' } & Pick<ProjectInfo, 'name' | 'slug'>> }
  >;
  total: { __typename?: 'ListMetadata' } & Pick<ListMetadata, 'count'>;
};

export const CreateUsersFinancialPlanDocument = gql`
  mutation CreateUsersFinancialPlan($input: CreateUsersFinancialPlanInputType!) {
    createUsersFinancialPlan(input: $input) {
      id
      createdAt
      status
    }
  }
`;
export type CreateUsersFinancialPlanMutationFn = Apollo.MutationFunction<
  CreateUsersFinancialPlanMutation,
  CreateUsersFinancialPlanMutationVariables
>;

/**
 * __useCreateUsersFinancialPlanMutation__
 *
 * To run a mutation, you first call `useCreateUsersFinancialPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUsersFinancialPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUsersFinancialPlanMutation, { data, loading, error }] = useCreateUsersFinancialPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUsersFinancialPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUsersFinancialPlanMutation, CreateUsersFinancialPlanMutationVariables>
) {
  return Apollo.useMutation<CreateUsersFinancialPlanMutation, CreateUsersFinancialPlanMutationVariables>(
    CreateUsersFinancialPlanDocument,
    baseOptions
  );
}
export type CreateUsersFinancialPlanMutationHookResult = ReturnType<typeof useCreateUsersFinancialPlanMutation>;
export type CreateUsersFinancialPlanMutationResult = Apollo.MutationResult<CreateUsersFinancialPlanMutation>;
export type CreateUsersFinancialPlanMutationOptions = Apollo.BaseMutationOptions<
  CreateUsersFinancialPlanMutation,
  CreateUsersFinancialPlanMutationVariables
>;
export const ExportWalletTransactionsToCsvDocument = gql`
  mutation ExportWalletTransactionsToCSV {
    exportWalletTransactionsToCSV
  }
`;
export type ExportWalletTransactionsToCsvMutationFn = Apollo.MutationFunction<
  ExportWalletTransactionsToCsvMutation,
  ExportWalletTransactionsToCsvMutationVariables
>;

/**
 * __useExportWalletTransactionsToCsvMutation__
 *
 * To run a mutation, you first call `useExportWalletTransactionsToCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportWalletTransactionsToCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportWalletTransactionsToCsvMutation, { data, loading, error }] = useExportWalletTransactionsToCsvMutation({
 *   variables: {
 *   },
 * });
 */
export function useExportWalletTransactionsToCsvMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportWalletTransactionsToCsvMutation,
    ExportWalletTransactionsToCsvMutationVariables
  >
) {
  return Apollo.useMutation<ExportWalletTransactionsToCsvMutation, ExportWalletTransactionsToCsvMutationVariables>(
    ExportWalletTransactionsToCsvDocument,
    baseOptions
  );
}
export type ExportWalletTransactionsToCsvMutationHookResult = ReturnType<
  typeof useExportWalletTransactionsToCsvMutation
>;
export type ExportWalletTransactionsToCsvMutationResult = Apollo.MutationResult<ExportWalletTransactionsToCsvMutation>;
export type ExportWalletTransactionsToCsvMutationOptions = Apollo.BaseMutationOptions<
  ExportWalletTransactionsToCsvMutation,
  ExportWalletTransactionsToCsvMutationVariables
>;
export const FinishUsersFinancialPlanDocument = gql`
  mutation FinishUsersFinancialPlan($id: ID!) {
    finishUsersFinancialPlan(id: $id) {
      id
    }
  }
`;
export type FinishUsersFinancialPlanMutationFn = Apollo.MutationFunction<
  FinishUsersFinancialPlanMutation,
  FinishUsersFinancialPlanMutationVariables
>;

/**
 * __useFinishUsersFinancialPlanMutation__
 *
 * To run a mutation, you first call `useFinishUsersFinancialPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishUsersFinancialPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishUsersFinancialPlanMutation, { data, loading, error }] = useFinishUsersFinancialPlanMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFinishUsersFinancialPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<FinishUsersFinancialPlanMutation, FinishUsersFinancialPlanMutationVariables>
) {
  return Apollo.useMutation<FinishUsersFinancialPlanMutation, FinishUsersFinancialPlanMutationVariables>(
    FinishUsersFinancialPlanDocument,
    baseOptions
  );
}
export type FinishUsersFinancialPlanMutationHookResult = ReturnType<typeof useFinishUsersFinancialPlanMutation>;
export type FinishUsersFinancialPlanMutationResult = Apollo.MutationResult<FinishUsersFinancialPlanMutation>;
export type FinishUsersFinancialPlanMutationOptions = Apollo.BaseMutationOptions<
  FinishUsersFinancialPlanMutation,
  FinishUsersFinancialPlanMutationVariables
>;
export const UpdateUsersFinancialPlanDocument = gql`
  mutation UpdateUsersFinancialPlan($id: ID!, $input: UpdateUsersFinancialPlanInputType!) {
    updateUsersFinancialPlan(id: $id, input: $input) {
      id
      updatedAt
      status
    }
  }
`;
export type UpdateUsersFinancialPlanMutationFn = Apollo.MutationFunction<
  UpdateUsersFinancialPlanMutation,
  UpdateUsersFinancialPlanMutationVariables
>;

/**
 * __useUpdateUsersFinancialPlanMutation__
 *
 * To run a mutation, you first call `useUpdateUsersFinancialPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUsersFinancialPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUsersFinancialPlanMutation, { data, loading, error }] = useUpdateUsersFinancialPlanMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUsersFinancialPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUsersFinancialPlanMutation, UpdateUsersFinancialPlanMutationVariables>
) {
  return Apollo.useMutation<UpdateUsersFinancialPlanMutation, UpdateUsersFinancialPlanMutationVariables>(
    UpdateUsersFinancialPlanDocument,
    baseOptions
  );
}
export type UpdateUsersFinancialPlanMutationHookResult = ReturnType<typeof useUpdateUsersFinancialPlanMutation>;
export type UpdateUsersFinancialPlanMutationResult = Apollo.MutationResult<UpdateUsersFinancialPlanMutation>;
export type UpdateUsersFinancialPlanMutationOptions = Apollo.BaseMutationOptions<
  UpdateUsersFinancialPlanMutation,
  UpdateUsersFinancialPlanMutationVariables
>;
export const BankAccountsDocument = gql`
  query BankAccounts {
    VerifiedBankAccounts {
      id
      iban
      accountNumber
      bankCode
      isDefault
    }
  }
`;

/**
 * __useBankAccountsQuery__
 *
 * To run a query within a React component, call `useBankAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBankAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBankAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBankAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<BankAccountsQuery, BankAccountsQueryVariables>
) {
  return Apollo.useQuery<BankAccountsQuery, BankAccountsQueryVariables>(BankAccountsDocument, baseOptions);
}
export function useBankAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BankAccountsQuery, BankAccountsQueryVariables>
) {
  return Apollo.useLazyQuery<BankAccountsQuery, BankAccountsQueryVariables>(BankAccountsDocument, baseOptions);
}
export type BankAccountsQueryHookResult = ReturnType<typeof useBankAccountsQuery>;
export type BankAccountsLazyQueryHookResult = ReturnType<typeof useBankAccountsLazyQuery>;
export type BankAccountsQueryResult = Apollo.QueryResult<BankAccountsQuery, BankAccountsQueryVariables>;
export const MonthlyGrossReturnsDocument = gql`
  query MonthlyGrossReturns {
    MonthlyGrossReturns {
      year
      month
      rentReturns {
        amount
        currency
      }
    }
  }
`;

/**
 * __useMonthlyGrossReturnsQuery__
 *
 * To run a query within a React component, call `useMonthlyGrossReturnsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonthlyGrossReturnsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonthlyGrossReturnsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMonthlyGrossReturnsQuery(
  baseOptions?: Apollo.QueryHookOptions<MonthlyGrossReturnsQuery, MonthlyGrossReturnsQueryVariables>
) {
  return Apollo.useQuery<MonthlyGrossReturnsQuery, MonthlyGrossReturnsQueryVariables>(
    MonthlyGrossReturnsDocument,
    baseOptions
  );
}
export function useMonthlyGrossReturnsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MonthlyGrossReturnsQuery, MonthlyGrossReturnsQueryVariables>
) {
  return Apollo.useLazyQuery<MonthlyGrossReturnsQuery, MonthlyGrossReturnsQueryVariables>(
    MonthlyGrossReturnsDocument,
    baseOptions
  );
}
export type MonthlyGrossReturnsQueryHookResult = ReturnType<typeof useMonthlyGrossReturnsQuery>;
export type MonthlyGrossReturnsLazyQueryHookResult = ReturnType<typeof useMonthlyGrossReturnsLazyQuery>;
export type MonthlyGrossReturnsQueryResult = Apollo.QueryResult<
  MonthlyGrossReturnsQuery,
  MonthlyGrossReturnsQueryVariables
>;
export const MonthlyProfitDocument = gql`
  query MonthlyProfit($page: Int!, $perPage: Int!) {
    items: profitsMonthlyHistory(page: $page, perPage: $perPage) {
      year
      month
      profit {
        amount
        currency
      }
      yield {
        amount
        currency
      }
      loss {
        amount
        currency
      }
      userLevelBonusYield {
        amount
        currency
      }
      bonusesAndOtherIncome {
        amount
        currency
      }
    }
    total: _profitsMonthlyHistoryMeta {
      count
    }
  }
`;

/**
 * __useMonthlyProfitQuery__
 *
 * To run a query within a React component, call `useMonthlyProfitQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonthlyProfitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonthlyProfitQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useMonthlyProfitQuery(
  baseOptions?: Apollo.QueryHookOptions<MonthlyProfitQuery, MonthlyProfitQueryVariables>
) {
  return Apollo.useQuery<MonthlyProfitQuery, MonthlyProfitQueryVariables>(MonthlyProfitDocument, baseOptions);
}
export function useMonthlyProfitLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MonthlyProfitQuery, MonthlyProfitQueryVariables>
) {
  return Apollo.useLazyQuery<MonthlyProfitQuery, MonthlyProfitQueryVariables>(MonthlyProfitDocument, baseOptions);
}
export type MonthlyProfitQueryHookResult = ReturnType<typeof useMonthlyProfitQuery>;
export type MonthlyProfitLazyQueryHookResult = ReturnType<typeof useMonthlyProfitLazyQuery>;
export type MonthlyProfitQueryResult = Apollo.QueryResult<MonthlyProfitQuery, MonthlyProfitQueryVariables>;
export const MyFinancialPlansDocument = gql`
  query MyFinancialPlans {
    myFinancialPlans {
      id
      createdAt
      updatedAt
      type
      status
      initialInvestment {
        amount
        currency
      }
      monthlyContribution {
        amount
        currency
      }
      initialAnnualPercentageYield
      savingsGoalAmount {
        amount
        currency
      }
      goalType
      terminationDate
    }
  }
`;

/**
 * __useMyFinancialPlansQuery__
 *
 * To run a query within a React component, call `useMyFinancialPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyFinancialPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyFinancialPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyFinancialPlansQuery(
  baseOptions?: Apollo.QueryHookOptions<MyFinancialPlansQuery, MyFinancialPlansQueryVariables>
) {
  return Apollo.useQuery<MyFinancialPlansQuery, MyFinancialPlansQueryVariables>(MyFinancialPlansDocument, baseOptions);
}
export function useMyFinancialPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyFinancialPlansQuery, MyFinancialPlansQueryVariables>
) {
  return Apollo.useLazyQuery<MyFinancialPlansQuery, MyFinancialPlansQueryVariables>(
    MyFinancialPlansDocument,
    baseOptions
  );
}
export type MyFinancialPlansQueryHookResult = ReturnType<typeof useMyFinancialPlansQuery>;
export type MyFinancialPlansLazyQueryHookResult = ReturnType<typeof useMyFinancialPlansLazyQuery>;
export type MyFinancialPlansQueryResult = Apollo.QueryResult<MyFinancialPlansQuery, MyFinancialPlansQueryVariables>;
export const TotalProfitDocument = gql`
  query TotalProfit {
    TotalProfit {
      amount
      currency
    }
  }
`;

/**
 * __useTotalProfitQuery__
 *
 * To run a query within a React component, call `useTotalProfitQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalProfitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalProfitQuery({
 *   variables: {
 *   },
 * });
 */
export function useTotalProfitQuery(
  baseOptions?: Apollo.QueryHookOptions<TotalProfitQuery, TotalProfitQueryVariables>
) {
  return Apollo.useQuery<TotalProfitQuery, TotalProfitQueryVariables>(TotalProfitDocument, baseOptions);
}
export function useTotalProfitLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TotalProfitQuery, TotalProfitQueryVariables>
) {
  return Apollo.useLazyQuery<TotalProfitQuery, TotalProfitQueryVariables>(TotalProfitDocument, baseOptions);
}
export type TotalProfitQueryHookResult = ReturnType<typeof useTotalProfitQuery>;
export type TotalProfitLazyQueryHookResult = ReturnType<typeof useTotalProfitLazyQuery>;
export type TotalProfitQueryResult = Apollo.QueryResult<TotalProfitQuery, TotalProfitQueryVariables>;
export const TotalUserLevelBonusYieldDocument = gql`
  query TotalUserLevelBonusYield($input: TotalUserLevelBonusYieldInputType!) {
    TotalUserLevelBonusYield(input: $input) {
      amount
      currency
    }
  }
`;

/**
 * __useTotalUserLevelBonusYieldQuery__
 *
 * To run a query within a React component, call `useTotalUserLevelBonusYieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalUserLevelBonusYieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalUserLevelBonusYieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTotalUserLevelBonusYieldQuery(
  baseOptions?: Apollo.QueryHookOptions<TotalUserLevelBonusYieldQuery, TotalUserLevelBonusYieldQueryVariables>
) {
  return Apollo.useQuery<TotalUserLevelBonusYieldQuery, TotalUserLevelBonusYieldQueryVariables>(
    TotalUserLevelBonusYieldDocument,
    baseOptions
  );
}
export function useTotalUserLevelBonusYieldLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TotalUserLevelBonusYieldQuery, TotalUserLevelBonusYieldQueryVariables>
) {
  return Apollo.useLazyQuery<TotalUserLevelBonusYieldQuery, TotalUserLevelBonusYieldQueryVariables>(
    TotalUserLevelBonusYieldDocument,
    baseOptions
  );
}
export type TotalUserLevelBonusYieldQueryHookResult = ReturnType<typeof useTotalUserLevelBonusYieldQuery>;
export type TotalUserLevelBonusYieldLazyQueryHookResult = ReturnType<typeof useTotalUserLevelBonusYieldLazyQuery>;
export type TotalUserLevelBonusYieldQueryResult = Apollo.QueryResult<
  TotalUserLevelBonusYieldQuery,
  TotalUserLevelBonusYieldQueryVariables
>;
export const UserInvestmentStatsDocument = gql`
  query UserInvestmentStats($input: UserInvestmentStatsInputType!) {
    UserInvestmentStats(input: $input) {
      avgInvestedAmount {
        value
        currency
      }
      numberOfInvestments
      lastInvestmentAt
    }
  }
`;

/**
 * __useUserInvestmentStatsQuery__
 *
 * To run a query within a React component, call `useUserInvestmentStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserInvestmentStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInvestmentStatsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserInvestmentStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<UserInvestmentStatsQuery, UserInvestmentStatsQueryVariables>
) {
  return Apollo.useQuery<UserInvestmentStatsQuery, UserInvestmentStatsQueryVariables>(
    UserInvestmentStatsDocument,
    baseOptions
  );
}
export function useUserInvestmentStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserInvestmentStatsQuery, UserInvestmentStatsQueryVariables>
) {
  return Apollo.useLazyQuery<UserInvestmentStatsQuery, UserInvestmentStatsQueryVariables>(
    UserInvestmentStatsDocument,
    baseOptions
  );
}
export type UserInvestmentStatsQueryHookResult = ReturnType<typeof useUserInvestmentStatsQuery>;
export type UserInvestmentStatsLazyQueryHookResult = ReturnType<typeof useUserInvestmentStatsLazyQuery>;
export type UserInvestmentStatsQueryResult = Apollo.QueryResult<
  UserInvestmentStatsQuery,
  UserInvestmentStatsQueryVariables
>;
export const UserWithdrawalFeesDocument = gql`
  query UserWithdrawalFees($bankAccountId: String!, $amount: Float!) {
    userWithdrawalFees(bankAccountId: $bankAccountId, amount: $amount) {
      baseWithdrawalFee {
        amount
        currency
      }
    }
  }
`;

/**
 * __useUserWithdrawalFeesQuery__
 *
 * To run a query within a React component, call `useUserWithdrawalFeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWithdrawalFeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWithdrawalFeesQuery({
 *   variables: {
 *      bankAccountId: // value for 'bankAccountId'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useUserWithdrawalFeesQuery(
  baseOptions?: Apollo.QueryHookOptions<UserWithdrawalFeesQuery, UserWithdrawalFeesQueryVariables>
) {
  return Apollo.useQuery<UserWithdrawalFeesQuery, UserWithdrawalFeesQueryVariables>(
    UserWithdrawalFeesDocument,
    baseOptions
  );
}
export function useUserWithdrawalFeesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserWithdrawalFeesQuery, UserWithdrawalFeesQueryVariables>
) {
  return Apollo.useLazyQuery<UserWithdrawalFeesQuery, UserWithdrawalFeesQueryVariables>(
    UserWithdrawalFeesDocument,
    baseOptions
  );
}
export type UserWithdrawalFeesQueryHookResult = ReturnType<typeof useUserWithdrawalFeesQuery>;
export type UserWithdrawalFeesLazyQueryHookResult = ReturnType<typeof useUserWithdrawalFeesLazyQuery>;
export type UserWithdrawalFeesQueryResult = Apollo.QueryResult<
  UserWithdrawalFeesQuery,
  UserWithdrawalFeesQueryVariables
>;
export const WalletDocument = gql`
  query Wallet {
    Wallet {
      variableSymbol
      availableBalance
    }
  }
`;

/**
 * __useWalletQuery__
 *
 * To run a query within a React component, call `useWalletQuery` and pass it any options that fit your needs.
 * When your component renders, `useWalletQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWalletQuery({
 *   variables: {
 *   },
 * });
 */
export function useWalletQuery(baseOptions?: Apollo.QueryHookOptions<WalletQuery, WalletQueryVariables>) {
  return Apollo.useQuery<WalletQuery, WalletQueryVariables>(WalletDocument, baseOptions);
}
export function useWalletLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WalletQuery, WalletQueryVariables>) {
  return Apollo.useLazyQuery<WalletQuery, WalletQueryVariables>(WalletDocument, baseOptions);
}
export type WalletQueryHookResult = ReturnType<typeof useWalletQuery>;
export type WalletLazyQueryHookResult = ReturnType<typeof useWalletLazyQuery>;
export type WalletQueryResult = Apollo.QueryResult<WalletQuery, WalletQueryVariables>;
export const WalletHistoryDocument = gql`
  query WalletHistory(
    $page: Int!
    $perPage: Int!
    $sortField: String!
    $sortOrder: String!
    $filter: WalletTransactionFilterType!
  ) {
    items: walletHistory(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      createdAt
      type
      amount
      currency
      projectInfo {
        name
        slug
      }
    }
    total: _walletHistoryMeta(filter: $filter) {
      count
    }
  }
`;

/**
 * __useWalletHistoryQuery__
 *
 * To run a query within a React component, call `useWalletHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useWalletHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWalletHistoryQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useWalletHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<WalletHistoryQuery, WalletHistoryQueryVariables>
) {
  return Apollo.useQuery<WalletHistoryQuery, WalletHistoryQueryVariables>(WalletHistoryDocument, baseOptions);
}
export function useWalletHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WalletHistoryQuery, WalletHistoryQueryVariables>
) {
  return Apollo.useLazyQuery<WalletHistoryQuery, WalletHistoryQueryVariables>(WalletHistoryDocument, baseOptions);
}
export type WalletHistoryQueryHookResult = ReturnType<typeof useWalletHistoryQuery>;
export type WalletHistoryLazyQueryHookResult = ReturnType<typeof useWalletHistoryLazyQuery>;
export type WalletHistoryQueryResult = Apollo.QueryResult<WalletHistoryQuery, WalletHistoryQueryVariables>;
