import { getPropertiesClient } from '../properties';

import { PageablePortfolioSizeMonthlyHistoryQuery } from './__generated__/sdk';
import { getCoreClient } from './client';
import { PageRequest } from './model';

export async function getPageablePortfolioSizeMonthlyHistory({
  pageRequest,
}: {
  pageRequest: PageRequest;
}): Promise<PageablePortfolioSizeMonthlyHistoryQuery> {
  const propertiesClient = await getPropertiesClient();
  const coreClient = await getCoreClient();
  const { page, perPage } = pageRequest;

  if (!coreClient.enabled) {
    return propertiesClient.PageablePortfolioSizeMonthlyHistory({
      page,
      perPage,
    });
  }
  return coreClient.PageablePortfolioSizeMonthlyHistory({
    page,
    perPage,
  });
}
