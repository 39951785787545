import Big from 'big.js';

/**
 * Future value formula (with regular contributions)
 * Based on https://www.vertex42.com/Calculators/compound-interest-calculator.html
 *
 * @param initialInvestment
 * @param annualRateDecimal Nominal annual interest rate (decimal) E.g. 0.045 for 4,5%
 * @param compoundFrequency Number of compounding periods per year
 * @param term Years of growth
 * @param regularPayment An investment added to the principal at the end of each payment period
 * @param regularPaymentFrequency Number of payment periods per year
 *
 * Caution: This calculator lets you choose Payment and Compounding combinations that don't necessarily make sense.
 * For example, a compound frequency of Monthly and a payment frequency of Weekly don't match up (there isn't an exact
 * number of weeks in a month). The math still gives a result, but it probably would not match what is going on from
 * week to week in an actual savings account.
 */
export function futureValueCalculator({
  initialInvestment,
  annualRateDecimal,
  compoundFrequency,
  term,
  regularPayment,
  regularPaymentFrequency,
}: {
  initialInvestment: number;
  annualRateDecimal: number;
  compoundFrequency: number;
  term: Big; // has to be Big to allow fractional terms (e.g. 4/12 for future value in 4 months)
  regularPayment: number;
  regularPaymentFrequency: number;
}): number {
  if (initialInvestment < 0) {
    throw new Error('initialInvestment must be a positive number');
  }
  if (term.lt(0)) {
    throw new Error('term must be a positive number');
  }
  if (compoundFrequency <= 0) {
    throw new Error('compoundFrequency must be a positive number');
  }
  if (regularPaymentFrequency <= 0) {
    throw new Error('paymentFrequency must be a positive number');
  }

  const regularPaymentFrequencyBig = Big(regularPaymentFrequency);

  const initialInvestmentBig = Big(initialInvestment);
  const monthlyPaymentBig = Big(regularPayment);

  const rateExponent = Big(compoundFrequency).div(regularPaymentFrequencyBig).toNumber();
  const rate = Math.pow(Big(1).add(Big(annualRateDecimal).div(compoundFrequency)).toNumber(), rateExponent) - 1;

  const exponent = Big(regularPaymentFrequencyBig.times(term)).toNumber();

  const compoundInterestForPrincipal = initialInvestmentBig.times(Math.pow(1 + rate, exponent));

  const futureValueOfSeries = monthlyPaymentBig.times(Big(Big(Math.pow(1 + rate, exponent)).minus(1)).div(rate));

  return compoundInterestForPrincipal.plus(futureValueOfSeries).round(2).toNumber();
}

interface FutureValueMonthlyArrayProps {
  month: number;
  value: number;
}

/**
 * Wrapper for futureValueCalculator to calculate future value monthly
 */
export function futureValueMonthly({
  initialInvestment,
  annualRateDecimal,
  compoundFrequency,
  years,
  regularPayment,
  regularPaymentFrequency,
}: {
  initialInvestment: number;
  annualRateDecimal: number;
  compoundFrequency: number;
  years: number;
  regularPayment: number;
  regularPaymentFrequency: number;
}): Array<FutureValueMonthlyArrayProps> {
  const result: Array<FutureValueMonthlyArrayProps> = [];

  for (let month = 0; month <= years * 12; month += 1) {
    const value = futureValueCalculator({
      term: Big(month).div(12),
      initialInvestment,
      annualRateDecimal,
      compoundFrequency,
      regularPayment,
      regularPaymentFrequency,
    });
    result.push({ month, value });
  }

  return result;
}

export function periodInvestmentsSum({
  initialInvestment,
  regularPayment,
  years,
}: {
  initialInvestment: number;
  regularPayment: number;
  years: number;
}): number {
  return Big(regularPayment).times(Big(years).times(12)).plus(Big(initialInvestment)).round(0).toNumber();
}
