import { getWalletClient } from '../wallet';

import { WalletQuery } from './__generated__/sdk';
import { getCoreClient } from './client';

export async function getWallet(): Promise<WalletQuery> {
  const walletClient = await getWalletClient();
  const coreClient = await getCoreClient();
  if (!coreClient.enabled) {
    return walletClient.Wallet();
  }
  return coreClient.Wallet();
}
