import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { QueryKeys } from '../enums';
import { UserInvestmentStatsQuery } from '../wallet/__generated__/sdk';
import { getUserInvestmentStats } from '../core';

export function useUserInvestmentStats(
  currency: string,
  queryOptions: UseQueryOptions<UserInvestmentStatsQuery, Error> = {}
): UseQueryResult<UserInvestmentStatsQuery, Error> {
  return useQuery<UserInvestmentStatsQuery, Error>(
    [QueryKeys.UserInvestmentStats, currency],
    () => getUserInvestmentStats({ currency }),
    {
      ...queryOptions,
    }
  );
}
