import { getPropertiesClient } from '../properties';

import { SecondaryMarketBidsQuery } from './__generated__/sdk';
import { PageRequest } from './model';
import { getCoreClient } from './client';

export async function getSecondaryMarketBids({
  pageRequest,
}: {
  pageRequest: PageRequest;
}): Promise<SecondaryMarketBidsQuery> {
  const propertiesClient = await getPropertiesClient();
  const coreClient = await getCoreClient();
  const { page, perPage } = pageRequest;

  if (!coreClient.enabled) {
    return propertiesClient.SecondaryMarketBids({
      page,
      perPage,
    });
  }
  return coreClient.SecondaryMarketBids({
    page,
    perPage,
  });
}
