import React, { FC } from 'react';
import styled from 'styled-components';

import { getLevelFromConfig, getUserLevelTranslations } from '@investown/fe/common-utils';
import { UserLevelConfigDTO } from '@investown/fe/api-sdk';

import LoadingSkeleton from 'components/V2/LoadingSkeleton/LoadingSkeleton';
import ProgressBarCircle from 'components/V2/ProgressBarCircle/ProgressBarCircle';
import Spacer from 'components/V2/Spacer/Spacer';
import Typography from 'components/V2/Typography/Typography';
import { theme } from 'styles/theme';
import { removePxSuffix } from 'util/helpers';
import { getAppLanguageLocalVariable } from 'lngProvider';

interface Props {
  levels: UserLevelConfigDTO[]; // data from hook useUserLevels()
  userLevel: string;
  userLevelProgress?: number;
  onPress?: () => void;
  size: 'small' | 'large';
}

const PremiumPill: FC<Props> = ({ levels, userLevel, userLevelProgress, size, onPress }) => {
  const userLocale = getAppLanguageLocalVariable();
  const mapUserLevel = getLevelFromConfig({ levelId: userLevel, levels });
  if (mapUserLevel === undefined) return null;
  const { isPremium } = mapUserLevel;

  const progressBarColor = isPremium
    ? {
        progressColor: theme.colorTokens.surface.inverted900,
        backgroundColor: theme.colorTokens.surface.overlayBlack,
      }
    : {
        progressColor: theme.colorTokens.icon.brand,
        backgroundColor: theme.colorTokens.surface.brandFaded200,
      };

  return (
    <PremiumLabelWrapper
      isPremium={isPremium}
      hasProgressBar={userLevelProgress !== undefined}
      size={size}
      onClick={onPress}
      data-testid="premium-pill"
    >
      {userLevelProgress !== undefined && (
        <>
          <ProgressBarCircle
            size={24}
            progress={userLevelProgress}
            progressColor={progressBarColor.progressColor}
            backgroundColor={progressBarColor.backgroundColor}
            strokeWidth={4}
          />
          <Spacer width="medium" />
        </>
      )}
      <Typography color="strong" variant={size === 'small' ? 'labelXSMedium' : 'labelSMMedium'}>
        {getUserLevelTranslations({ locale: userLocale, toTranslate: mapUserLevel })}
      </Typography>
    </PremiumLabelWrapper>
  );
};

export const PremiumPillLoadingSkeleton = () => (
  <LoadingSkeleton
    width={72 + removePxSuffix(theme.spacing.regular)}
    height={42}
    borderRadius={theme.borderRadius.extraLarge}
  />
);

const PremiumLabelWrapper = styled.div<{ isPremium: boolean; hasProgressBar: boolean; size: 'small' | 'large' }>`
  height: ${({ size }) => (size === 'large' ? '42px' : '32px')};
  background: ${({ hasProgressBar, isPremium }) => {
    if (isPremium) {
      return `linear-gradient(266deg, ${theme.colorTokens.gradient.gold.start} 1.64%, ${theme.colorTokens.gradient.gold.middle} 51.9%, ${theme.colorTokens.gradient.gold.end} 99.12%)`;
    }
    if (hasProgressBar) {
      return 'transparent';
    }
    return theme.colorTokens.surface.brandFaded100;
  }};
  padding: ${({ hasProgressBar, isPremium, size }) => {
    if (!isPremium && hasProgressBar) {
      return `${theme.spacing.medium} ${theme.spacing.regular} ${theme.spacing.medium} ${theme.spacing.regular};`;
    }
    if (isPremium && hasProgressBar) {
      return `${theme.spacing.medium} ${theme.spacing.large} ${theme.spacing.medium} ${theme.spacing.regular};`;
    }
    if (size === 'large') {
      return `${theme.spacing.regular} ${theme.spacing.large};`;
    }
    return `${theme.spacing.medium} ${theme.spacing.regular};`;
  }}
  border-radius: ${theme.borderRadius.extraLarge};
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  cursor: pointer;
`;

export default PremiumPill;
