import { getPropertiesClient } from '../properties';

import { InvestmentRoundFeesQuery } from './__generated__/sdk';
import { getCoreClient } from './client';

export async function getInvestmentRoundFees(investmentRoundId: string): Promise<InvestmentRoundFeesQuery> {
  const propertiesClient = await getPropertiesClient();
  const coreClient = await getCoreClient();
  if (!coreClient.enabled) {
    return propertiesClient.InvestmentRoundFees({ investmentRoundId });
  }
  return coreClient.InvestmentRoundFees({ investmentRoundId });
}
