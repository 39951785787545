import { getPropertiesClient } from '../properties';

import { PropertyQuery } from './__generated__/sdk';
import { getCoreClient } from './client';

export async function getProperty(slug: string): Promise<PropertyQuery> {
  const propertiesClient = await getPropertiesClient();
  const coreClient = await getCoreClient();
  if (!coreClient.enabled) {
    return propertiesClient.Property({ slug });
  }
  return coreClient.Property({ slug });
}
