import { QueryFunctionContext, useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';

import * as WalletSDK from '../wallet/__generated__/sdk';
import { getWalletHistory, GetWalletHistoryProps } from '../core/getWalletHistory';
import { QueryKeys } from '../enums';

type WalletProps = Pick<GetWalletHistoryProps, 'perPage' | 'sortField' | 'sortOrder' | 'filter'>;
// eslint-disable-next-line @typescript-eslint/naming-convention
type beforeRequestCallback = ({
  pageParam,
  variables,
}: {
  pageParam: QueryFunctionContext<[string, WalletProps]>['pageParam'];
  variables: QueryFunctionContext<[string, WalletProps]>['queryKey'][1];
}) => void;
export const useInfiniteTransactions = (
  {
    perPage = 20,
    sortField = 'createdAt',
    sortOrder = 'DESC',
    filter,
  }: {
    perPage?: number;
    sortField?: string;
    sortOrder?: string;
    filter?: WalletSDK.WalletTransactionFilterType;
  },
  queryOptions?: Parameters<
    typeof useInfiniteQuery<WalletSDK.WalletHistoryQuery, Error, WalletSDK.WalletHistoryQuery, [string, WalletProps]>
  >[2] & {
    onBeforeRequest?: beforeRequestCallback;
  }
): UseInfiniteQueryResult<WalletSDK.WalletHistoryQuery, Error> => {
  return useInfiniteQuery<WalletSDK.WalletHistoryQuery, Error, WalletSDK.WalletHistoryQuery, [string, WalletProps]>(
    [QueryKeys.WalletHistory, { perPage, sortField, sortOrder, filter: { allowedTypes: filter?.allowedTypes } }],
    ({ pageParam = 0, queryKey: [, variables] }) => {
      if (queryOptions?.onBeforeRequest && typeof queryOptions.onBeforeRequest === 'function') {
        queryOptions.onBeforeRequest({ pageParam, variables });
      }

      return getWalletHistory({
        page: pageParam,
        perPage: variables.perPage,
        sortField: variables.sortField,
        sortOrder: variables.sortOrder,
        filter: variables.filter,
      });
    },
    {
      ...queryOptions,
      getNextPageParam: (lastPage, pages) => (lastPage.items.length < perPage ? undefined : pages.length),
    }
  );
};
