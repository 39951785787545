/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useFeatureFlag } from 'configcat-react';

export function withFeatureFlag<T extends object>(
  Component: React.ComponentType<T>,
  { flag, fallBackValue }: { flag: string; fallBackValue: boolean }
) {
  return (props: T) => {
    const { value } = useFeatureFlag(flag, fallBackValue);

    if (!value) {
      return null;
    }

    return <Component {...props} />;
  };
}
