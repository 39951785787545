import { getPropertiesClient } from '../properties';
import { PropertyInvestmentRoundActivityQuery } from '../properties/__generated__/sdk';

import { getCoreClient } from './client';

export async function getPropertyInvestmentRoundActivity(slug: string): Promise<PropertyInvestmentRoundActivityQuery> {
  const propertiesClient = await getPropertiesClient();
  const coreClient = await getCoreClient();
  if (!coreClient.enabled) {
    return propertiesClient.PropertyInvestmentRoundActivity({ slug });
  }
  return coreClient.PropertyInvestmentRoundActivity({ slug });
}
