import { getWalletClient } from '../wallet';

import { ExportWalletTransactionsToCsvMutation } from './__generated__/sdk';
import { getCoreClient } from './client';

export async function exportWalletTransactionsToCSV(): Promise<ExportWalletTransactionsToCsvMutation> {
  const walletClient = await getWalletClient();
  const coreClient = await getCoreClient();
  if (!coreClient.enabled) {
    return walletClient.ExportWalletTransactionsToCSV();
  }
  return coreClient.ExportWalletTransactionsToCSV();
}
