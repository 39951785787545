export enum UserVerificationStatus {
  NotVerified = 'not_verified',
  InProgress = 'in_progress',
  WaitingForApproval = 'waiting_for_approval',
  Verified = 'verified',
  /**
   * User's verification version is too low to perform all operations.
   * User is only able to offboard his account or upgrade his verification.
   */
  HasOutdatedVerificationVersion = 'has_outdated_verification_version',
  Declined = 'declined',
}

/** Version of the verification process */
export enum UserVerificationVersion {
  Initial = 1,
  WithPersonalIdentifierOrBirthDate = 2,
  Aml = 3,
  AmlWithCrowdfundingTermsAndConditionsAgreement = 4,
  WithConfirmedContract = 5,
}

/** Version of the legal entity verification process */
export enum LegalEntityVerificationVersion {
  Initial = 1,
  WithConfirmedContract = 2,
}

export enum DismissedBannerCode {
  YouBecamePremium = 'you_became_premium',
  SecondaryMarket = 'secondary_market',
  HowItWorks = 'how_it_works',
  Currency = 'currency',
  ReferralBanner = 'referral_banner',
  MobileAppBanner = 'mobile_app_banner',
  PremiumBannerGratulation = 'premium_banner_gratulation',
  PremiumBannerSorry = 'premium_banner_sorry',
  PremiumBannerMotivation = 'premium_banner_motivation',
}

/** Investor type according to our CrowdFunding license */
export enum UserInvestorTypeEnum {
  Sophisticated = 'sophisticated',
  NotSophisticated = 'not_sophisticated',
}
